import { useState, useEffect } from 'react';
import getLocationFromIP, { LocationFromIP } from '@/lib/ip';

const useLocation = (): LocationFromIP | null => {
  const [location, setLocation] = useState<LocationFromIP | null>(null);

  useEffect(() => {
    const getIP = async () => {
      try {
        const userLocation = await getLocationFromIP();
        if (userLocation) {
          setLocation(userLocation);
        }
      } catch (error) {
        setLocation(null);
      }
    };
    getIP();
  }, [setLocation]);

  return location;
};

export default useLocation;
