import { setUserId, setUserProperties } from 'firebase/analytics';
import { User } from 'firebase/auth';
import posthog from 'posthog-js';
import * as gtag from '@/lib/gtag';
import { analytics } from '@/firebase/firebase';
import { msManagement } from '@/lib/msImportantActivities';
import { IUser } from '@/types/Collections/users';
import eventAdd from '@/lib/eventAdd';

export const analyticsIdentifyUser = (
  uid: string,
  user: IUser,
  isAdmin = false,
): void => {
  identifyGA(uid, user);
  identifyPosthog(uid, user, isAdmin);
};

export const identifyGA = (uid: string, user: IUser): void => {
  if (analytics) {
    const thisYear = new Date().getFullYear();
    setUserId(analytics, uid ? uid : null);
    setUserProperties(analytics, {
      genderOption: user.genderOption || null,
      msConnection: user.msConnection || null,
      msType: user.msType || null,
      treatment: user.treatment || null,
      yearDiagnosed: user.yearDiagnosed || null,
      yearsSinceDiagnosis: user.yearDiagnosed
        ? thisYear - Number(user.yearDiagnosed)
        : null,
    });
  }
  gtag.pushData('userdata', {
    uid: uid,
    loggedIn: true,
  });
};

export const identifyPosthog = (
  uid: string,
  user: IUser,
  isAdmin = false,
): void => {
  if (posthog) {
    posthog.identify(uid);
    const thisYear = new Date().getFullYear();
    const sortedImportantActivitiesList: msManagement[] = [];
    if (
      user.msImportantActivitiesList &&
      user.msImportantActivitiesList.length
    ) {
      const sortedImportantActivitiesListArr =
        user.msImportantActivitiesList.sort((a, b) => {
          if (a.rank && b.rank) {
            return a.rank - b.rank;
          }
          return 0;
        });
      sortedImportantActivitiesListArr.forEach(item => {
        if (item.rank) {
          sortedImportantActivitiesList.push(item.name);
        }
      });
    }
    posthog.people.set({
      isAdmin,
      genderOption: user.genderOption || null,
      msConnection: user.msConnection || null,
      msType: user.msType || null,
      treatment: user.treatment || null,
      yearDiagnosed: user.yearDiagnosed || null,
      yearsSinceDiagnosis: user.yearDiagnosed
        ? thisYear - Number(user.yearDiagnosed)
        : null,
      isTakingDMT: user.isTakingDMT || null,
      reasonForNotUsingDMT: user.reasonForNotUsingDMT || null,
      monthDmtStarted: user.monthDmtStarted || null,
      yearDmtStarted: user.yearDmtStarted || null,
      isOnDesiredDMT: user.isOnDesiredDMT || null,
      dmtInfluentialPerson: user.dmtInfluentialPerson || null,
      dmtRecommendationScore: user.dmtRecommendationScore || null,
      importantFactorInDmtDecision: user.importantFactorInDmtDecision || null,
      msImportantActivitiesList: sortedImportantActivitiesList.join(', '),
    });
  }
};

export const userActiveEvent = async (
  authResult: User,
): Promise<unknown | false> => {
  if (authResult.uid && authResult.email) {
    const promises = [];
    promises.push(
      eventAdd({
        uid: authResult.uid,
        eventType: 'USER:ACTIVE',
        eventSource: 'USER',
        eventSourceId: authResult.uid,
        meta: {},
        email: authResult.email,
        platform: 'web',
      }),
    );
    if (authResult.metadata?.creationTime) {
      const created = new Date(authResult.metadata.creationTime);
      const today = new Date();
      const date28DaysAgo = today.setUTCDate(today.getUTCDate() - 28);
      if (created.getTime() >= date28DaysAgo) {
        promises.push(
          eventAdd({
            uid: authResult.uid,
            eventType: 'NEW_USER:ACTIVE',
            eventSource: 'USER',
            eventSourceId: authResult.uid,
            meta: { created },
            email: authResult.email,
            platform: 'web',
          }),
        );
      }
    }
    return Promise.allSettled(promises);
  }
  return Promise.resolve(false);
};
