import { UserInfo } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/firebase/firebase';
import { IUser } from '@/types/Collections/users';

export const isAdminAccount = (userResult: UserInfo): boolean => {
  if (userResult && /-admin@shift.ms\s*$/.test(userResult.email || '')) {
    return true;
  }
  return false;
};

export const fixStreamAccessToken = async (
  uid: string,
  user: IUser,
): Promise<string | false> => {
  if (user && user.username) {
    const streamAccessTokenFunction = httpsCallable(
      functions,
      'streamCallable-generateStreamAccessToken',
    );
    return streamAccessTokenFunction({
      uid,
      username: user.username,
      profileImgUrl: user.profileImgUrl || null,
    })
      .then(result => {
        if (result.data) {
          return result.data as string;
        }
        return false;
      })
      .catch(() => false);
  }
  return Promise.resolve(false);
};
