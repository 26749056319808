const authPaths = [
  '/signin',
  '/join',
  '/reset',
  '/password',
  '/magiclink',
  '/fb',
  '/username',
  '/welcome',
  '/verify',
];

const isAuthPath = (path: string): boolean => authPaths.includes(path);

export default isAuthPath;
