import { getAuth, signOut } from 'firebase/auth';

const signoutUser = async (): Promise<boolean> => {
  const auth = getAuth();
  return signOut(auth)
    .then(() => true)
    .catch(() => false);
};

export default signoutUser;
