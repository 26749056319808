import { createContext } from 'react';
import { ISignupBannerState } from './types';

const defaultValue: ISignupBannerState = {
  isExpanded: false,
  open: () => {},
  close: () => {},
  message: '',
  setMessage: () => {},
  resetMessage: () => {},
};

const SignupBannerContext = createContext<ISignupBannerState>(defaultValue);

export default SignupBannerContext;
