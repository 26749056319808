import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';

export default function useNetworkDetector(): void {
  const { enqueueSnackbar } = useSnackbar();

  const router = useRouter();

  const renderOfflineToast = useCallback(() => {
    enqueueSnackbar('You are online ', { variant: 'success' });
    window.location.reload();
  }, [enqueueSnackbar]);

  const renderOnlineToast = useCallback(() => {
    enqueueSnackbar('You are offline', { variant: 'error' });
  }, [enqueueSnackbar]);

  useEffect(() => {
    window.addEventListener('online', renderOfflineToast);

    window.addEventListener('offline', renderOnlineToast);

    return function cleanupListener() {
      window.removeEventListener('online', renderOfflineToast);
      window.removeEventListener('online', renderOnlineToast);
    };
  }, [router, renderOnlineToast, renderOfflineToast]);
}
