import { useCallback, useEffect } from 'react';
import usePreviousValue from './usePrevious';

declare global {
  interface Window {
    _hsq: any;
  }
}

interface IReturnType {
  addHubspotPageView: (newPath?: string) => void;
  setIdentity: (email: string, customPropertities?: object) => void;
}

const useHubspotTracking = (path?: string): IReturnType => {
  const addHubspotPageView = useCallback((newPath?: string) => {
    const _hsq =
      typeof window !== 'undefined' && window._hsq ? window._hsq : [];
    _hsq.push(['setPath', newPath]);
    _hsq.push(['trackPageView']);
  }, []);

  const setIdentity = useCallback(
    (email: string, customPropertities?: object) => {
      const _hsq =
        typeof window !== 'undefined' && window._hsq ? window._hsq : [];
      _hsq.push([
        'identify',
        {
          email,
          ...customPropertities,
        },
      ]);
    },
    [],
  );

  const prevPathRef = usePreviousValue(path);

  useEffect(() => {
    if (path && path !== prevPathRef) {
      addHubspotPageView(path);
    }
  }, [addHubspotPageView, path, prevPathRef]);

  return {
    addHubspotPageView,
    setIdentity,
  };
};

export default useHubspotTracking;
