import React, {
  useMemo,
  useReducer,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { IAuthState } from './types';
import AuthContext from './context';
import { authInitialState, authReducer } from './reducer';
import { handleAuthResult, handleSetUnseenNotificationsCount } from './actions';
import { fixStreamAccessToken } from './utils';
import useUser, { updateUser } from './hooks/useUser';
import useSettings from './hooks/useSettings';
import { analyticsIdentifyUser, userActiveEvent } from './lib/analytics';
import useHubspotTracking from '@/hooks/useHubspotTracking';
import { IUser } from '@/types/Collections/users';

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const { setIdentity: setHubspotIdentity } = useHubspotTracking();
  const [state, dispatch] = useReducer(authReducer, authInitialState);

  const { data: user } = useUser(state.uid);
  const { data: settings } = useSettings(state.uid);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, authResult => {
      handleAuthResult(dispatch, authResult);
      if (authResult?.uid && authResult?.email) {
        setHubspotIdentity(authResult.email);
        userActiveEvent(authResult);
      } else {
        dispatch({ type: 'SET_SIGNED_OUT' });
      }
    });
    return () => unsubscribe();
  }, [setHubspotIdentity]);

  useEffect(() => {
    const handleMissingStreamAccessToken = async (uid: string, user: IUser) => {
      const token = await fixStreamAccessToken(uid, user);
      if (token) {
        updateUser(uid, { streamAccessToken: token });
      }
    };
    if (state.uid && user && settings) {
      dispatch({ type: 'AUTH_LOADED' });
      if (user && !user.streamAccessToken) {
        handleMissingStreamAccessToken(state.uid, user);
      }
      analyticsIdentifyUser(state.uid, user, state.isAdmin);
    }
    if (!state.isVerified && !!settings?.emailVerified) {
      dispatch({
        type: 'SET_EMAIL_VERIFIED',
      });
    }
  }, [state.uid, state.isAdmin, state.isVerified, user, settings]);

  const setUnseenNotificationsCount = useCallback((count: number) => {
    handleSetUnseenNotificationsCount(dispatch, count);
  }, []);

  const showVerifyEmailDialog = () => {
    dispatch({ type: 'SHOW_VERIFY_EMAIL_DIALOG' });
  };

  const hideVerifyEmailDialog = () => {
    dispatch({ type: 'HIDE_VERIFY_EMAIL_DIALOG' });
  };

  const contextState = useMemo(() => {
    return { ...state, user, settings };
  }, [state, user, settings]);

  return (
    <AuthContext.Provider
      value={{
        ...contextState,
        setUnseenNotificationsCount,
        showVerifyEmailDialog,
        hideVerifyEmailDialog,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthState => useContext(AuthContext);

export default AuthProvider;
