import {
  useState, useEffect, useCallback, useRef,
} from 'react';
import dynamic from 'next/dynamic';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import {
  serverTimestamp, setDoc, doc, updateDoc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../firebase/firebase';
import NpsScore from './NpsScore';
import Feedback from './Feedback';
import MoreFeedback from './MoreFeedback';
import Thankyou from './Thankyou';
import { useAuth } from '../../providers/AuthProvider';
import PageLoading from '../General/PageLoading';

const Dialog = dynamic(() => import('@mui/material/Dialog'), {
  loading: () => <PageLoading />,
});

const NpsModal = () => {
  const testMode = false;
  const npsId = 'default2022';

  const { authLoaded, uid, user, settings, auth } = useAuth();

  const [currentStep, setCurrentStep] = useState('score');
  const [trigger, setTrigger] = useState('');

  const [showModal, setShowModal] = useState(false);
  const timer = useRef(null);

  const updateSettings = async (payload) => {
    try {
      if (uid) {
        await updateDoc(doc(db, 'settings', uid), payload);
      }
    } catch (error) {
      // console.log(error, 'error!');
    }
  };

  const setFeedback = (data) => {
    setDoc(doc(db, 'nps', npsId, 'users', uid), data, { merge: true });
  };

  const initialNpsState = {
    score: null,
    status: '',
    created: null,
    feedback: '',
    feedbackNote: '',
    complete: false,
    exit: '',
  };

  const [npsData, setNpsData] = useState(initialNpsState);

  const eventAdd = useCallback(
    async (eventType) => {
      const eventAddFunction = httpsCallable(
        functions,
        'eventCallable-eventAdd',
      );
      if (uid && auth.email) {
        eventAddFunction({
          uid,
          eventType: `NPS:${eventType}`,
          eventSource: 'NPS',
          eventSourceId: npsId,
          meta: { trigger, ...npsData },
          email: auth.email,
          platform: 'web'
        });
      }
    },
    [uid, npsId, trigger, npsData, auth?.email],
  );

  useEffect(() => {
    const handleSave = async () => {
      try {
        setFeedback({
          ...npsData,
          created: serverTimestamp(),
          trigger,
        });
        eventAdd('UPDATE');
        updateSettings({ isNpsSeen: true });
      } catch (err) {
        // console.log(err, 'updateNPS [Err!]');
      }
    };
    if (uid && (npsData.score || (npsData.exit && npsData.exit !== ''))) {
      handleSave();
    }
  }, [uid, npsData, trigger, eventAdd]);

  useEffect(() => {
    if (uid) {
      if (testMode) {
        setShowModal(true);
        setTrigger('test');
        eventAdd('SHOW');
      } else if (
        authLoaded &&
        settings?.isNpsSeen
        && (npsData.score === null || npsData.exit !== '')
      ) {
        // npsData.score === null i.e. hasn't been started
        // npsData.exit !== '' i.e. modal has been closed
        setShowModal(false);
        clearTimeout(timer.current);
        /* } else if (settings.postViewCount === 4) {
      const time = settings?.lastPostViewTime.toDate();
      time.setSeconds(time.getSeconds());
      setTrigger('views');
      if (time < new Date()) {
        setTimeout(() => { setShowModal(false); }, 15000);
      }
      setTimeout(() => { setShowModal(true); }, 15000); */
        /* } else if (settings?.firstCommentTime) {
      const time = settings?.firstCommentTime.toDate();
      time.setSeconds(time.getSeconds());
      if (time < new Date()) {
        setTrigger('comment');
        setTimeout(() => { setShowModal(true); }, 15000);
      } */
      } else if (settings?.firstPostCreatedTime) {
        const time = settings?.firstPostCreatedTime.toDate();
        time.setSeconds(time.getSeconds());
        if (time < new Date()) {
          setTrigger('post');
          timer.current = setTimeout(() => {
            setShowModal(true);
            eventAdd('SHOW');
          }, 15000);
        }
      } else if (settings?.firstFollowTime) {
        const time = settings?.firstFollowTime.toDate();
        time.setSeconds(time.getSeconds());
        if (time < new Date()) {
          setTrigger('follow');
          timer.current = setTimeout(() => {
            setShowModal(true);
            eventAdd('SHOW');
          }, 15000);
        }
      }
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [uid, settings, testMode, eventAdd, npsData.score, npsData.exit]);

  // Reset NPS if uid changes
  useEffect(() => {
    setNpsData(initialNpsState);
  }, [uid]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setNpsData({ ...npsData, exit: reason || 'closed' });
      setShowModal(false);
      eventAdd('CLOSE');
    }
  };

  const renderTitle = () => {
    switch (currentStep) {
      case 'feedback':
        return (
          <>
            <h2 className="is-size-6 is-hidden-touch" id="nps-modal-title">
              We&apos;d love to improve things around here, can you tell us a
              little more?
            </h2>
            <div className="is-hidden-desktop" />
          </>
        );

      case 'other':
        return (
          <h2 className="is-size-6" id="nps-modal-title">
            We&apos;d love to improve things around here, can you tell us a
            little more?
          </h2>
        );

      case 'score': {
        switch (trigger) {
          // All use same title currently
          case 'views':
          case 'follow':
          case 'comment':
          case 'post':
          default:
            return (
              <h2 className="is-size-6" id="nps-modal-title">
                Thanks for spending some time on Shift.ms, would you recommend
                us to a newly diagnosed MSer?
              </h2>
            );
        }
      }
      case 'people':
      case 'hard':
      case 'questions':
      case 'thanks':
      case 'feedbackthanks':
      default:
        return <div />;
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case 'feedback':
        return (
          <Feedback
            setCurrentStep={setCurrentStep}
            npsData={npsData}
            setNpsData={setNpsData}
          />
        );

      case 'other':
        return (
          <MoreFeedback
            setCurrentStep={setCurrentStep}
            npsData={npsData}
            setNpsData={setNpsData}
          />
        );

      case 'people':
      case 'hard':
      case 'questions':
      case 'thanks':
      case 'feedbackthanks':
        return (
          <Thankyou
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            npsData={npsData}
            setNpsData={setNpsData}
            username={user.username}
            handleClose={handleClose}
          />
        );

      default:
        return (
          <NpsScore
            setCurrentStep={setCurrentStep}
            npsData={npsData}
            setNpsData={setNpsData}
          />
        );
    }
  };

  if (uid) {
    return (
      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby="nps-modal-title"
      >
        <div className="card nps-modal">
          <div className="card-content">
            <div className="modal-header">
              {renderTitle()}
              <div className="modal-close-button">
                <button
                  type="button"
                  aria-label="Close"
                  onClick={handleClose}
                  className="button is-light"
                >
                  <span className="icon">
                    <Icon path={mdiClose} size={1.25} />
                  </span>
                </button>
              </div>
            </div>
            <div className="nps-modal-content">{renderContent()}</div>
          </div>
        </div>
      </Dialog>
    );
  }

  return null;
};

export default NpsModal;
