import { mdiArrowLeft, mdiMagnify, mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import isAuthPath from '@/lib/authPaths';
import { useSignupBanner } from '@/providers/SignupBannerProvider';
import { useNav } from '@/providers/NavProvider';
import ShiftmsLogo from '../svg/Shiftms';

const TopNav = ({
  mainMenuActive,
  setMainMenuActive,
}: {
  mainMenuActive: boolean;
  setMainMenuActive: (isActive: boolean) => void;
}): JSX.Element => {
  const { backLink } = useNav();
  const router = useRouter();
  const { resetMessage } = useSignupBanner();

  const toggleMainMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setMainMenuActive(!mainMenuActive);
  };

  const handleClickBackArrow = () => {
    if (backLink) {
      let { href } = backLink;
      if (href.indexOf('?') > -1) {
        href = `${href}&back=1`;
      } else {
        href = `${href}?back=1`;
      }
      if (backLink.as === '/home') {
        resetMessage();
      }
      // If collection post then we need to force back to a non-modal view
      if (href.indexOf('collectionId') !== -1) {
        href = '/[...slug]';
        backLink.options.shallow = false;
      }
      router.push(href, backLink.as, backLink.options);
    }
  };

  const backArrow = () => {
    if (backLink && !router.query.back) {
      return (
        <div className="navbar-item">
          <a
            role="button"
            onClick={handleClickBackArrow}
            onKeyDown={handleClickBackArrow}
            tabIndex={0}
            className="button is-white">
            <Icon path={mdiArrowLeft} size={1.4} />
          </a>
        </div>
      );
    }
    return '';
  };

  return (
    <nav className="navbar is-fixed-top topnav">
      <div className="navbar-motion">
        <div className="container">
          <div className="navbar-brand">
            <Link href="/home" legacyBehavior>
              <a className="navbar-item shiftms-logo" title="Shift.ms">
                <ShiftmsLogo width="32" height="36" fill="#73B62A" />
                <span>Shift.ms</span>
              </a>
            </Link>
          </div>

          {!isAuthPath(router.pathname) ? (
            <>
              {backArrow()}
              <div className="navbar-item navbar-item-donate">
                <a
                  rel="noreferrer"
                  href="https://shift.ms/get-involved/donate"
                  className="button is-primary is-rounded has-text-weight-bold is-small"
                  title="Donate"
                  target="_blank">
                  <span>Donate</span>
                </a>
              </div>
              <div className="navbar-right">
                <div className="navbar-item">
                  <Link href="/explore" legacyBehavior>
                    <a
                      aria-label="Explore"
                      className="button is-white"
                      title="Explore">
                      <Icon path={mdiMagnify} size={1.4} />
                    </a>
                  </Link>
                </div>
                <div className="navbar-item">
                  <button
                    type="button"
                    aria-label="Toggle Menu"
                    onClick={toggleMainMenu}
                    className="button is-white">
                    <Icon path={mdiMenu} size={1.4} />
                  </button>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="learn-nav navbar-menu">
        <div className="container">
          <div className="learn-nav-item">
            <a
              href="https://shift.ms/learn-about-ms"
              className="navbar-item learn-nav-heading">
              Learn about MS
            </a>
          </div>
          <div className="learn-nav-item is-justify-content-center">
            <div className="navbar-item has-dropdown is-hoverable learn-nav-symptoms">
              <a className="navbar-link">MS symptoms</a>
              <div className="navbar-dropdown">
                <a
                  href="https://shift.ms/learn-about-ms/ms-symptoms"
                  className="navbar-item">
                  MS symptoms
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-symptoms/a-guide-to-ms-symptoms"
                  className="navbar-item">
                  A guide to MS symptoms
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-symptoms/what-are-the-early-signs-of-ms"
                  className="navbar-item">
                  What are the early signs of MS?
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-symptoms/understanding-visible-and-invisible-symptoms-of-ms"
                  className="navbar-item">
                  Understanding visible and invisible symptoms of MS
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-symptoms/ms-hug"
                  className="navbar-item">
                  MS Hug
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-symptoms/ms-and-optic-neuritis"
                  className="navbar-item">
                  MS and optic neuritis
                </a>
              </div>
            </div>
          </div>
          <div className="learn-nav-item is-justify-content-center">
            <div className="navbar-item has-dropdown is-hoverable learn-nav-diagnosis">
              <a className="navbar-link">MS diagnosis</a>
              <div className="navbar-dropdown">
                <a
                  href="https://shift.ms/learn-about-ms/ms-diagnosis"
                  className="navbar-item">
                  MS diagnosis
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-diagnosis/being-diagnosed-with-ms"
                  className="navbar-item">
                  Being diagnosed with MS
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-diagnosis/what-is-ms"
                  className="navbar-item">
                  What is MS?
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-diagnosis/the-different-types-of-multiple-sclerosis"
                  className="navbar-item">
                  Different types of MS
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-diagnosis/is-ms-hereditary"
                  className="navbar-item">
                  Is MS hereditary?
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-diagnosis/late-onset-ms-and-treatment"
                  className="navbar-item">
                  Late onset MS and treatment
                </a>
              </div>
            </div>
          </div>
          <div className="learn-nav-item is-justify-content-center">
            <div className="navbar-item has-dropdown is-hoverable learn-nav-treatment">
              <a className="navbar-link">MS treatment</a>
              <div className="navbar-dropdown">
                <a
                  href="https://shift.ms/learn-about-ms/ms-treatment"
                  className="navbar-item">
                  MS treatment
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-treatment/getting-treatment-for-ms"
                  className="navbar-item">
                  Getting treatment for MS
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-treatment/coping-with-ms-fatigue"
                  className="navbar-item">
                  Coping with MS fatigue
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-treatment/exercising-with-multiple-sclerosis"
                  className="navbar-item">
                  Exercising with MS
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-treatment/understanding-ms-relapse"
                  className="navbar-item">
                  Understanding MS relapse
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/ms-treatment/hsct-treatment-for-ms"
                  className="navbar-item">
                  HSCT treatment for MS
                </a>
              </div>
            </div>
          </div>
          <div className="learn-nav-item is-justify-content-flex-end">
            <div className="navbar-item has-dropdown is-hoverable learn-nav-living">
              <a className="navbar-link">Living with MS</a>
              <div className="navbar-dropdown is-right">
                <a
                  href="https://shift.ms/learn-about-ms/living-with-ms"
                  className="navbar-item">
                  Living with MS
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/living-with-ms/after-diagnosis-living-with-ms"
                  className="navbar-item">
                  After diagnosis: living with MS
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/living-with-ms/multiple-sclerosis-and-mental-health"
                  className="navbar-item">
                  Multiple sclerosis and mental health
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/living-with-ms/working-with-ms"
                  className="navbar-item">
                  Working with MS
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/living-with-ms/ms-sex-and-relationships"
                  className="navbar-item">
                  MS, sex and relationships
                </a>
                <a
                  href="https://shift.ms/learn-about-ms/living-with-ms/a-guide-to-ms-benefits-in-the-uk"
                  className="navbar-item">
                  A guide to MS benefits in the UK
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopNav;
