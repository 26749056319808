import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const FileUploadInput = ({
  children,
  onChange,
  accept = 'image/*',
  ...props
}) => {
  const ref = useRef();
  const selectImage = () => {
    ref.current.click();
  };
  return (
    <div onClick={selectImage}>
      {children}
      <input
        {...props}
        ref={ref}
        onChange={onChange}
        style={{ display: 'none' }}
        type="file"
        size="60"
        accept={accept}
      />
    </div>
  );
};

FileUploadInput.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  accept: PropTypes.string,
};

FileUploadInput.defaultProps = {
  children: null,
  onChange: () => {},
  accept: 'image/*',
};

export default FileUploadInput;
