import Cookies from 'js-cookie';

export interface LocationFromIP {
  businessName: string;
  city: string;
  continent: string;
  country: string;
  countryCode: string;
  ipName: string;
  isp: string;
  lat: number;
  lon: number;
  query: string;
  region: string;
  status: string;
  timezone: string;
}

const getLocationFromIP = async (): Promise<LocationFromIP | null> => {
  const cookieName = 'userLocationFromIP';

  const savedCookie = Cookies.get(cookieName);
  if (savedCookie) {
    const result = JSON.parse(savedCookie) as LocationFromIP;
    return result;
  }

  return fetch(
    `https://extreme-ip-lookup.com/json/?key=${process.env.NEXT_PUBLIC_EXTREME_IP_LOOKUP}`,
  )
    .then(res => res.json())
    .then(response => {
      Cookies.set(cookieName, JSON.stringify(response), {
        expires: 1,
        path: '/',
      });
      return response as LocationFromIP;
    })
    .catch(() => {
      return null;
    });
};

export default getLocationFromIP;
