import { useState } from 'react';

interface IProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  valid: boolean;
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
  characterCount: number;
  setCharacterCount: React.Dispatch<React.SetStateAction<number>>;
  reset: () => void;
  bind: {
    value: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
  };
}

const useCharacterLimitInput = (
  initialValue: string,
  maxCharacterCount = 100,
): IProps => {
  const isNotEmpty = (val: string): boolean => {
    if (val.length > 0) {
      return true;
    }
    return false;
  };

  const countLength = (val: string): number => {
    if (val.length > 0) {
      return val.length;
    }
    return 0;
  };

  const [value, setValue] = useState<string>(initialValue);
  const [valid, setValid] = useState<boolean>(isNotEmpty(initialValue));
  const [characterCount, setCharacterCount] = useState<number>(
    countLength(initialValue),
  );

  return {
    value,
    setValue,
    valid,
    setValid,
    characterCount,
    setCharacterCount,
    reset: (): void => {
      setValue(initialValue);
      setValid(isNotEmpty(initialValue));
      setCharacterCount(0);
    },
    bind: {
      value,
      onChange: (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value.length <= maxCharacterCount) {
          setValue(event.target.value);
          setValid(isNotEmpty(event.target.value));
          setCharacterCount(countLength(event.target.value));
        }
      },
    },
  };
};

export default useCharacterLimitInput;
