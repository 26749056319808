import dayjs from 'dayjs';
import slugify from 'react-slugify';
import { IPost } from '../types/Collections/posts';
import { ICollection } from '../types/Collections/collections';

export const convertArrayToObject = (array: any[], key: string):string => {
  const initialValue: {[key: string]: any} = {};
  return array.reduce((obj, item) => ({
    ...obj,
    [item[key]]: item,
  }), initialValue);
};

export const truncateString = (str: string, num = 60):string => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const validateYouTubeUrl = (url: string):boolean => {
  if (url !== undefined || url !== '') {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return true;
    }
  }
  return false;
};

export const getUrlType = (url: string):string => {
  const urlObj = new URL(url);
  if (urlObj.hostname === 'shift.ms') {
    return 'shiftms';
  }
  if (urlObj.hostname === 'shiftms.typeform.com') {
    return 'default';
  }
  if (validateYouTubeUrl(url)) {
    return 'youtube';
  }
  return 'default';
};

export const postUrl = (post: IPost):string => {
  let slug = slugify(post.title);
  slug = slug.substring(0, 64);
  return `/@${post.author.username}/${slug}-${post.id}`;
};

export const collectionUrl = (collection: ICollection): string | undefined => {
  if (collection.author) {
    if (collection.id === 'bookmarks') {
      return `/@${collection.author.username}/collections/bookmarks`;
    }
    let slug = slugify(collection.title);
    slug = slug.substring(0, 64);
    return `/@${collection.author.username}/collections/${slug}-${collection.id}`;
  }
  return undefined;
};

export const pronoun = (genderOption: string):string => {
  switch (genderOption) {
    case 'Male':
      return 'him';
    case 'Female':
      return 'her';
    default:
      return 'them';
  }
};

export const isNewUser = (created: string | Date | any):boolean => {
  if (created) {
    let diff = 200;
    if (typeof created.toDate === 'function') {
      diff = dayjs().diff(dayjs(created.toDate()), 'days');
    } else if (typeof created === 'string') {
      diff = dayjs().diff(dayjs(created), 'days');
    } else {
      return false;
    }
    if (diff < 180) {
      return true;
    }
  }
  return false;
};

export function notNull<T>(val: T | null): val is T {
    return val !== null;
}
