import { mdiMinusCircleOutline, mdiPlusCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { v4 as uuidv4 } from 'uuid';
import { PostPollOption } from '@/types/Collections/posts';

const PostFormPoll = ({
  pollLocked = false,
  pollOptions,
  setPollOptions,
  bindPollQuestion,
}: {
  pollLocked?: boolean;
  pollOptions: PostPollOption[];
  setPollOptions: React.Dispatch<React.SetStateAction<PostPollOption[]>>;
  bindPollQuestion: {
    value: string;
  };
}): JSX.Element => {
  const addInputField = () => {
    setPollOptions([
      ...pollOptions,
      {
        placeholder: `Option ${pollOptions.length + 1} ( Optional )`,
        id: uuidv4(),
        value: '',
        vote: 0,
        isOptional: true,
      },
    ]);
  };

  const removePollOptionField = (index: number) => {
    const newFormValues = [...pollOptions];
    newFormValues.splice(index, 1);
    setPollOptions(newFormValues);
  };

  const updatePollOptions = (pollOption: PostPollOption) => {
    const tempPollOptions = [...pollOptions];
    const i = tempPollOptions.findIndex(e => e.id === pollOption.id);

    tempPollOptions[i] = {
      ...pollOption,
    };

    setPollOptions(tempPollOptions);
  };

  return (
    <div className="mb-2">
      <div className="field">
        <div className="control">
          <input
            className="input is-normal mt-3 mb-3"
            placeholder="Ask a question..."
            autoComplete="off"
            disabled={pollLocked}
            {...bindPollQuestion}
          />
        </div>
      </div>

      {pollOptions.map((element, index) => (
        <div className="field m-0" key={element.id}>
          <label
            id={`poll-option-label-${element.id}`}
            htmlFor={element.id}
            className="label is-hidden">
            Name
          </label>
          <div className="control">
            <input
              className="input is-normal"
              autoComplete="off"
              id={element.id}
              disabled={pollLocked}
              maxLength={25}
              placeholder={element.placeholder}
              value={element.value}
              aria-labelledby={`poll-option-label-${element.id}`}
              onChange={e =>
                updatePollOptions({
                  ...element,
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <p className="help has-text-right">
              {element.value?.length}
              /25
            </p>
          </div>
          <div className="is-flex is-justify-content-flex-end create-poll-options">
            {element.isOptional &&
              element.id === pollOptions[pollOptions.length - 1]?.id && (
                <button
                  type="button"
                  aria-label="Minus"
                  onClick={() => removePollOptionField(index)}
                  className={`${
                    pollOptions.length === 0 || pollLocked
                      ? 'is-hidden '
                      : 'is-block '
                  } button is-white is-small`}>
                  <span className="icon">
                    <Icon path={mdiMinusCircleOutline} size={1} />
                  </span>
                </button>
              )}
            {element.id === pollOptions[pollOptions.length - 1]?.id && (
              <button
                type="button"
                aria-label="Plus"
                onClick={addInputField}
                className={`${
                  pollOptions.length === 5 || pollLocked
                    ? 'is-hidden '
                    : 'is-block '
                } button is-white is-small`}>
                <span className="icon">
                  <Icon path={mdiPlusCircleOutline} size={1} />
                </span>
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PostFormPoll;
