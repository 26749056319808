interface IParsedUrl {
  url: string;
  type: 'page' | 'profile' | 'collection' | 'stream' | 'post';
  params: {
    collectionId?: string;
    username?: string;
    postId?: string;
    filter?: string;
  };
}

const parseUrl = (url: string): IParsedUrl => {
  const result: IParsedUrl = {
    url,
    type: 'page',
    params: {},
  };

  const slugparts = url.substring(1).split('/');

  if (slugparts[0].substring(0, 1) === '@') {
    // Is profile, profile section, post or collection
    result.type = 'profile';
    result.params.username = slugparts[0].substring(1);
    if (slugparts.length > 1) {
      switch (slugparts[1]) {
        case 'followers':
        case 'following':
        case 'about':
        case 'followers.json':
        case 'following.json':
        case 'about.json':
          result.type = 'profile';
          break;

        case 'collections':
        case 'collections.json': {
          result.type = 'collection';
          const [, , collectionslug] = slugparts;
          const collectionslugparts = collectionslug.split('-');
          const collectionId =
            collectionslugparts[collectionslugparts.length - 1];
          result.params.collectionId = collectionId;
          break;
        }

        default: {
          result.type = 'post';
          const [, postslug] = slugparts;
          const postslugparts = postslug.split('-');
          const postId = postslugparts[postslugparts.length - 1];
          result.params.postId = postId;
          break;
        }
      }
    }
  }

  if (slugparts.length === 1 && slugparts[0] === 'home') {
    result.type = 'stream';
  }
  return result;
};

export default parseUrl;
