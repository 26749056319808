import { useState } from 'react';
import PropTypes from 'prop-types';

const Feedback = ({
  setCurrentStep, setNpsData, npsData,
}) => {
  const [userFeedback, setUserFeedback] = useState('');

  const handleChange = (e) => {
    setUserFeedback(e.target.value);
    setNpsData({ ...npsData, feedback: e.target.id });
  };

  const handleSubmit = () => {
    setCurrentStep(userFeedback);
  };

  const handleSkip = () => {
    if (userFeedback === 'other') { setCurrentStep('other'); } else setCurrentStep('thanks');
  };

  return (
    <>
      <div className="nps-modal-background-grey-circle" />
      <div className="nps-modal-background nps-modal-background-left" />
      <div className="nps-modal-content-center">
        <h2 className="is-size-4 is-relative is-hidden-desktop">
          We&apos;d  love to improve things around here, can you tell us a little more?
        </h2>
        <div className="control is-flex is-flex-direction-column is-size-6 nps-radios">
          <label className="radio m-2">
            <input
              id="The website is hard to use"
              type="radio"
              value="hard"
              aria-labelledby="0"
              name="1"
              onChange={handleChange}
            />
            <span className="ml-2">The website is hard to use</span>
          </label>
          <label className="radio m-2">
            <input
              id="I cannot find answers to my questions"
              type="radio"
              value="questions"
              aria-labelledby="1"
              name="1"
              onChange={handleChange}
            />
            <span className="ml-2">I can&apos;t find answers to my questions</span>
          </label>
          <label className="radio m-2">
            <input
              id="I cannot find people i want to connect"
              type="radio"
              value="people"
              aria-labelledby="2"
              name="1"
              onChange={handleChange}
            />
            <span className="ml-2">I can&apos;t find people I want to connect</span>
          </label>
          <label className="radio m-2">
            <input
              id="Something else"
              type="radio"
              value="other"
              aria-labelledby="3"
              name="1"
              onChange={handleChange}
            />
            <span className="ml-2">Something else</span>
          </label>
        </div>
      </div>
      <div className="buttons">
        <button
          className="button is-light is-normal feedback-submit mt-3"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            setCurrentStep('score');
          }}
        >
          Back

        </button>
        <button
          className={`button ${userFeedback.length ? ' is-primary' : 'is-light'} 
                is-normal feedback-submit mt-3`}
          type="submit"
          onClick={userFeedback.length ? handleSubmit : handleSkip}
        >
          {userFeedback.length ? 'Next' : 'Skip'}
        </button>
      </div>
    </>

  );
};

Feedback.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  setNpsData: PropTypes.func.isRequired,
  npsData: PropTypes.object.isRequired,
};

export default Feedback;
