import algoliasearch from 'algoliasearch';
import { IPost } from '@/types/Collections/posts';
import { IProfile } from '@/types/Collections/users';
import { SearchResponse } from '@algolia/client-search';

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
);

const search = async (
  query: string,
  collection: string,
  hitsPerPage: number,
  page = 0,
): Promise<SearchResponse<IPost | IProfile> | null> => {
  if (collection) {
    const index = searchClient.initIndex(collection);
    try {
      let resultsPage = 0;
      if (page > 1) {
        resultsPage = page - 1;
      }
      const content = await index.search<IPost | IProfile>(query, {
        page: resultsPage,
        hitsPerPage,
      });
      return content;
    } catch (err) {
      // console.error(err);
    }
  }
  return Promise.resolve(null);
};

export default search;
