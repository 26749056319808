// https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
import { useEffect, useState, useMemo } from 'react';

type UseTimeInputReturnType = {
  value: Date;
  setValue: React.Dispatch<React.SetStateAction<Date>>;
  stringValue: string;
  setStringValue: React.Dispatch<React.SetStateAction<string>>;
  setHour: React.Dispatch<React.SetStateAction<string>>;
  setMinutes: React.Dispatch<React.SetStateAction<string>>;
  setYear: React.Dispatch<React.SetStateAction<string>>;
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  setDay: React.Dispatch<React.SetStateAction<string>>;
  reset: () => void;
  bind: {
    value: Date;
    onChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
  };
  bindYear: {
    value: string;
    onChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
  };
  bindMonth: {
    value: string;
    onChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
  };
  bindDay: {
    value: string;
    onChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
  };
  bindHours: {
    value: string;
    onChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
  };
  bindMinutes: {
    value: string;
    onChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => void;
  };
  valid: boolean;
  isDateValid: boolean;
  setValid: React.Dispatch<React.SetStateAction<boolean>>;
};

const useTimeInput = (initialDate: Date): UseTimeInputReturnType => {
  const initialValue = useMemo(() => {
    let initialHours = '';
    let initialMinutes = '';
    let initialYear = '';
    let initialMonth = '';
    let initialDay = '';
    let initialDateString = '';

    if (initialDate.valueOf()) {
      initialHours = initialDate.getHours().toString();
      initialMinutes = (initialDate.getMinutes() + 1).toString();
      initialDay = initialDate.getDate().toString();
      initialYear = initialDate.getFullYear().toString();
      initialMonth = initialDate.getMonth().toString();
      initialDateString = `${initialYear}-${initialMonth + 1}-${initialDay}`;
    }

    return {
      initialHours,
      initialMinutes,
      initialDate,
      initialYear,
      initialMonth,
      initialDay,
      initialDateString,
    };
  }, [initialDate]);

  const [value, setValue] = useState<Date>(initialValue.initialDate);
  const [stringValue, setStringValue] = useState<string>(
    initialValue.initialDateString,
  );
  const [hour, setHour] = useState<string>(initialValue.initialHours);
  const [minutes, setMinutes] = useState<string>(initialValue.initialMinutes);
  const [year, setYear] = useState<string>(initialValue.initialYear);
  const [month, setMonth] = useState<string>(initialValue.initialMonth);
  const [day, setDay] = useState<string>(initialValue.initialDay);

  const [valid, setValid] = useState<boolean>(false);
  const [isDateValid, setIsDateValid] = useState<boolean>(true);

  useEffect(() => {
    const checkValid = () => {
      if (
        hour !== '' &&
        minutes !== '' &&
        year !== '' &&
        day !== '' &&
        month !== ''
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
    };
    const newDate = new Date(
      parseInt(year, 10),
      parseInt(month, 10),
      parseInt(day, 10),
      parseInt(hour, 10),
      parseInt(minutes, 10),
    );
    if (new Date().getTime() > newDate.getTime()) {
      setIsDateValid(false);
    } else {
      setIsDateValid(true);
    }
    setValue(newDate);
    setStringValue(`${year}-${parseInt(month, 10) + 1}-${day}`);
    checkValid();
  }, [day, hour, minutes, month, setValue, year]);

  return {
    value,
    setValue,
    stringValue,
    setStringValue,
    setHour,
    setMonth,
    setYear,
    setMinutes,
    setDay,
    isDateValid,
    bindYear: {
      value: year,
      onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => {
        setYear(event.target.value);
      },
    },
    bindMonth: {
      value: month,
      onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => {
        setMonth(event.target.value);
      },
    },
    bindDay: {
      value: day,
      onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => {
        setDay(event.target.value);
      },
    },
    reset: () => {
      setValue(new Date());
      setStringValue('');
      setYear('');
      setMonth('');
      setDay('');
    },
    bind: {
      value,
      onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => {
        setValue(new Date(event.target.value));
      },
    },
    valid,
    setValid,

    bindHours: {
      value: hour,
      onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => {
        setHour(event.target.value);
      },
    },
    bindMinutes: {
      value: minutes,
      onChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      ) => {
        setMinutes(event.target.value);
      },
    },
  };
};

export default useTimeInput;
