import React from 'react';
import { User } from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '@/firebase/firebase';

interface IHandleAuthResult {
  authResult: User | null;
}

export const handleAuthResult = async (
  dispatch: React.Dispatch<any>,
  authResult: User | null,
): Promise<IHandleAuthResult> => {
  dispatch({ type: 'AUTH_LOADING' });
  if (authResult) {
    const userDoc = doc(db, 'users', authResult.uid);
    const settingsDoc = doc(db, 'settings', authResult.uid);

    // Check to see if is existing user or new
    const settingsDocExists = await getDoc(settingsDoc).then(doc => {
      if (doc.exists()) {
        return true;
      }
      return false;
    });

    const initPromises = [];

    if (settingsDocExists) {
      initPromises.push(
        setDoc(
          userDoc,
          {
            lastActive: serverTimestamp(),
          },
          { merge: true },
        ),
      );

      initPromises.push(
        setDoc(
          settingsDoc,
          {
            lastActive: serverTimestamp(),
            emailVerified: authResult.emailVerified,
          },
          { merge: true },
        ),
      );
    } else {
      initPromises.push(
        setDoc(
          userDoc,
          {
            lastActive: serverTimestamp(),
          },
          { merge: true },
        ),
      );

      initPromises.push(
        setDoc(
          settingsDoc,
          {
            lastActive: serverTimestamp(),
            emailVerified: authResult.emailVerified,
            needsOnboarding: true,
            needsTour: true,
          },
          { merge: true },
        ),
      );
    }

    await Promise.all(initPromises);
    dispatch({ type: 'SET_AUTH_RESULT', payload: { authResult } });
  } else {
    dispatch({ type: 'SET_SIGNED_OUT' });
  }

  return { authResult };
};

export const handleSetUnseenNotificationsCount = (
  dispatch: React.Dispatch<any>,
  notificationsCount: number,
): void => {
  dispatch({
    type: 'SET_UNSEEN_NOTIFICATIONS_COUNT',
    payload: { count: notificationsCount },
  });
};
