import { useState } from 'react';
import PropTypes from 'prop-types';

const MoreFeedback = ({
  setCurrentStep, setNpsData, npsData,
}) => {
  const [userFeedbackText, setUserFeedbackText] = useState('');

  const handleSubmit = () => {
    setNpsData({ ...npsData, feedbackNote: userFeedbackText });
    setCurrentStep('feedbackthanks');
  };

  const handleSkip = () => {
    setCurrentStep('feedbackthanks');
  };

  return (
    <>
      <div className="nps-modal-background-blue-circle nps-modal-background-blue-circle-lower" />
      <div className="nps-modal-background nps-modal-background-more-feedback" />
      <div className="nps-modal-background nps-modal-background-more-feedback-left" />
      <div className="nps-modal-background nps-modal-background-more-feedback-right" />
      <div className="nps-modal-content-stretch">
        <div className="control is-flex is-flex-direction-column is-size-6 nps-radios">
          <label className="radio m-2">
            <input
              id=""
              type="radio"
              value="other"
              aria-labelledby="3"
              name="1"
              checked
              readOnly
            />
            <span className="ml-2">Something else</span>
          </label>

        </div>
        <textarea
          className="textarea"
          placeholder="Type something...&#10;Feel free to be specific, and as detailed as you like, to help us understand."
          onChange={(e) => {
            setUserFeedbackText(e.target.value);
          }}
        />
      </div>

      <div className="buttons">
        <button
          className="button is-light is-normal feedback-submit mt-3"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setCurrentStep('feedback');
          }}
        >
          Back
        </button>
        <button
          className={`button ${userFeedbackText.length ? ' is-primary' : 'is-light'} 
                is-normal feedback-submit mt-3`}
          type="button"
          onClick={userFeedbackText.length ? handleSubmit : handleSkip}
        >
          {userFeedbackText.length ? 'Next' : 'Skip'}
        </button>
      </div>
    </>
  );
};

MoreFeedback.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  setNpsData: PropTypes.func.isRequired,
  npsData: PropTypes.object.isRequired,
};

export default MoreFeedback;
