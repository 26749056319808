import PropTypes from 'prop-types';

const NpsScore = ({ setCurrentStep, setNpsData, npsData }) => {
  const handleFeedback = (e, score) => {
    e.preventDefault();
    if (score > 8) {
      setCurrentStep('thanks');
    } else {
      setCurrentStep('feedback');
    }
    if (score <= 6) {
      setNpsData({ ...npsData, score, status: 'detractor' });
    } else if (score === 7 || score === 8) {
      setNpsData({ ...npsData, score, status: 'passive' });
    } else {
      setNpsData({ ...npsData, score, status: 'promoter' });
    }
  };

  return (
    <div className="nps-score">
      <div className="is-hidden-touch mb-1 nps-score-labels-desktop">
        <span className="is-font-brand is-size-6">
          No, not at all
        </span>
        <span className="is-font-brand is-size-6">
          Yes, definitely
        </span>
      </div>
      <div className="nps-score-buttons is-size-6">
        <div className="is-font-brand is-hidden-desktop is-size-6 is-hidden-desktop has-text-centered">
          Yes, definitely
        </div>
        <button
          className="button is-primary m-1 is-fullwidth nps-score-button is-rounded"
          style={{
            background: '#7EC4A3',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 10)}
        >
          10
        </button>
        <button
          className="button is-primary m-1 is-fullwidth nps-score-button is-rounded"
          style={{
            background: '#9EC888',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 9)}
        >
          9
        </button>
        <button
          className="button is-primary is-fullwidth m-1 nps-score-button is-rounded"
          style={{
            background: '#BFCB6C',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 8)}
        >
          8
        </button>
        <button
          className="button is-primary is-fullwidth m-1 nps-score-button is-rounded"
          style={{
            background: '#FFD235',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 7)}
        >
          7
        </button>
        <button
          className="button is-primary is-fullwidth m-1 nps-score-button is-rounded"
          style={{
            background: '#FCC13B',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 6)}
        >
          6
        </button>
        <button
          className="button is-primary is-fullwidth m-1 nps-score-button is-rounded"
          style={{
            background: '#F9B142',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 5)}
        >
          5
        </button>
        <button
          className="button is-primary is-fullwidth m-1 nps-score-button is-rounded"
          style={{
            background: '#F6A048',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 4)}
        >
          4
        </button>
        <button
          className="button is-primary is-fullwidth m-1 nps-score-button is-rounded"
          style={{
            background: '#F3904F',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 3)}
        >
          3
        </button>
        <button
          className="button is-primary is-fullwidth m-1 nps-score-button is-rounded"
          style={{
            background: '#F07F55',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 2)}
        >
          2
        </button>
        <button
          className="button is-primary is-fullwidth m-1 nps-score-button is-rounded"
          style={{
            background: '#ED6F5C',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 1)}
        >
          1
        </button>
        <button
          className="button is-primary is-fullwidth m-1 nps-score-button is-rounded"
          style={{
            background: '#EA5E62',
          }}
          type="button"
          onClick={(e) => handleFeedback(e, 0)}
        >
          0
        </button>
        <div className="is-font-brand is-hidden-desktop is-size-6 is-hidden-desktop has-text-centered">
          No, not at all
        </div>
      </div>
    </div>
  );
};

NpsScore.propTypes = {
  setNpsData: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  npsData: PropTypes.object.isRequired,
};

export default NpsScore;
