import FileUploadInput from '@/components/General/FileUploadInput';

const PostFormImageUpload = ({
  image,
  handleImageUpload,
  resetImage,
}: {
  image: string | null;
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  resetImage: () => void;
}): JSX.Element => {
  return (
    <div>
      {!image ? (
        <FileUploadInput onChange={handleImageUpload}>
          <button type="button" className="button is-light mb-2 is-small">
            <span>Add Picture</span>
          </button>
        </FileUploadInput>
      ) : (
        <button
          onClick={resetImage}
          type="button"
          className="button is-danger mb-2 is-small">
          <span>Remove Picture</span>
        </button>
      )}
    </div>
  );
};
export default PostFormImageUpload;
