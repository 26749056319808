import useSWRSubscription from 'swr/subscription';
import type {
  SWRSubscriptionOptions,
  SWRSubscriptionResponse,
} from 'swr/subscription';
import { IUser } from '@/types/Collections/users';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import { IAuthResponse } from '../types';
import { AuthErrorResponse, AuthSuccessResponse } from '../lib/authResponse';

export const updateUser = async (
  uid: string,
  userObj: Partial<IUser>,
): Promise<IAuthResponse> => {
  return updateDoc(doc(db, 'users', uid), userObj)
    .then(() => {
      return new AuthSuccessResponse();
    })
    .catch(err => {
      return new AuthErrorResponse(err);
    });
};

const useUser = (
  uid: string | null,
): SWRSubscriptionResponse<IUser | null, Error> => {
  const sub = (
    key: string,
    { next }: SWRSubscriptionOptions<IUser | null, Error>,
  ) => {
    const docRef = doc(db, `users/${uid}`);
    const unsubscribe = onSnapshot(
      docRef,
      docSnapshot => {
        next(null, docSnapshot.data() as IUser);
      },
      err => {
        next(err, null);
      },
    );
    return () => unsubscribe();
  };
  return useSWRSubscription(() => (uid ? `users/${uid}` : null), sub);
};

export default useUser;
