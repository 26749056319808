import * as React from 'react';

const SvgShiftms = (props) => (
  <svg
    width={462.766}
    height={395.894}
    viewBox="240.548 271.417 462.766 395.894"
    {...props}
  >
    <path d="M276.089 596.105c-19.663-.006-35.582 15.962-35.541 35.606-.09 19.623 15.91 35.476 35.649 35.6 19.585-.089 35.634-15.985 35.556-35.538-.022-19.601-15.94-35.657-35.664-35.668m369.793-324.687l-313.279-.001c-31.728.023-57.408 25.139-57.396 56.265l-.055 158.853c.046 28.061 21.015 51.291 48.318 55.579l20.945.171-.102 73.325c.145 6.729 4.221 12.797 10.599 15.334 2.082.767 4.186 1.196 6.428 1.188 4.314-.055 8.648-1.681 11.87-4.744l64.752-84.598 207.939.064c31.713 0 57.306-25.251 57.413-56.278l-.026-158.85c-.01-31.007-25.664-56.227-57.406-56.308M513.493 386.192l-.031 79.193c0 11.095-9.027 20.094-20.232 20.167-11.09-.04-20.204-9.055-20.133-20.269l-.037-79.15c-.04-.028-.017-.068.088-.106 0 0-.014-.092-.113-.116.02-9.095-7.46-16.571-16.427-16.548-9.231.015-16.583 7.422-16.563 16.532l-.009.082c.046.006-.025.084-.02.138l.085 79.157c-.091 11.169-9.134 20.28-20.247 20.348-11.197-.136-20.244-9.163-20.204-20.343l-.085-79.068c.074-.146.082-.224.043-.229l-.006-.04c-.082-9.11-7.46-16.494-16.572-16.485-8.956-.053-16.339 7.321-16.458 16.378l-.003.104.031.052-.085 79.278c.054 11.271-9.001 20.292-20.079 20.241-11.234.023-20.312-9.12-20.286-20.196l.046-79.232-.048-.136c.017-.125.023-.163.062-.38l-.062-36.388c.031-11.14 9.134-20.181 20.232-20.157 5.137.026 9.727 1.962 13.268 5.05 7.166-3.183 15.07-5.045 23.423-5.081a57.47 57.47 0 0136.869 13.449c9.817-8.337 22.604-13.462 36.674-13.43 31.421-.028 56.852 25.417 56.779 56.913.045.044.096.13.077.207l.023.065m80.06 100.799c-23.686-.125-58.836-13.254-58.816-31.886.048-6.256 6.46-18.269 18.037-18.269 16.541-.051 23.715 14.03 43.655 13.951 12.894.048 16.648-3.938 16.674-11.713-.017-18.96-73.033-16.022-73.113-63.321.077-28.687 23.74-48.045 57.017-48.214 20.822.139 52.622 9.571 52.622 29.918.088 9.462-7.262 18.331-17.215 18.34-7.879.027-23.933-12.104-37.739-12.061-7.63.061-14.336 3.442-14.319 11.364.059 19.238 73.042 16.351 73.144 63.481-.074 27.574-23.772 48.379-59.947 48.41" />
  </svg>
);

export default SvgShiftms;
