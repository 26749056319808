import { useMemo } from 'react';

type SniperLinkConfig = {
  link: string;
  provider: string;
  title: string;
};

const useSniperLink = (email: string | null): SniperLinkConfig | null => {
  const sniperLinkConfig = useMemo(() => {
    if (email) {
      if (email.includes('@gmail')) {
        return {
          link: `https://mail.google.com/mail/u/0/#search/from%3A(${process.env.NEXT_PUBLIC_VERIFICATION_EMAIL_ID})+in%3Aanywhere+newer_than%3A1h`,
          provider: 'gmail',
          title: 'Open Gmail',
        };
      }

      if (
        email.includes('@outlook') ||
        email.includes('@hotmail') ||
        email.includes('@live')
      ) {
        return {
          link: `https://outlook.live.com/mail/?login_hint=${encodeURIComponent(
            email,
          )}`,
          provider: 'outlook',
          title: 'Open Outlook',
        };
      }

      if (email.includes('@yahoo')) {
        return {
          link: `https://mail.yahoo.com/d/search/keyword=from%253A${process.env.NEXT_PUBLIC_VERIFICATION_EMAIL_ID}`,
          provider: 'yahoo',
          title: 'Open Yahoo',
        };
      }
    }
    return null;
  }, [email]);

  return sniperLinkConfig;
};

export default useSniperLink;
