import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import NProgress from 'nprogress';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import useNetworkDetector from '@/hooks/useNetworkDetector';
import { useAuth } from '@/providers/AuthProvider';
import PageLoading from '@/components/General/PageLoading';
import MainMenu from '@/components/Nav/MainMenu';
import Navbar from '@/components/Nav/Navbar';
import TopNav from '@/components/Nav/TopNav';
import isAuthPath from '@/lib/authPaths';

const SignupBanner = dynamic(() => import('@/components/Auth/SignupBanner'), {
  ssr: false,
});

const WelcomeTour = dynamic(() => import('@/components/General/WelcomeTour'), {
  ssr: false,
});

const ProfileBuilderLink = dynamic(
  () => import('@/components/Auth/ProfileBuilderLink'),
  {
    ssr: false,
  },
);

const EmailVerfiyPromptDialog = dynamic(
  () => import('@/components/Auth/EmailVerifyPromptDialog'),
  {
    ssr: false,
  },
);

NProgress.configure({ showSpinner: false });

ReactModal.setAppElement('#__next');

interface PageProps {
  children: React.ReactNode;
}

const SiteLayout: React.FC<PageProps> = ({ children }: PageProps) => {
  const { isLoggedIn, uid } = useAuth();

  useNetworkDetector();

  const router = useRouter();

  const [mainMenuActive, setMainMenuActive] = useState<boolean>(false);
  const [loadingScreen, showLoadingScreen] = useState(false);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      NProgress.start();
    };
    const handleRouteChangeEnd = () => {
      NProgress.done();
      showLoadingScreen(false);
    };
    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeEnd);
    Router.events.on('routeChangeError', handleRouteChangeEnd);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeEnd);
      Router.events.off('routeChangeError', handleRouteChangeEnd);
    };
  }, []);

  if (loadingScreen) {
    return <PageLoading />;
  }

  return (
    <div
      className={`layout ${
        isLoggedIn ? 'authenticated' : 'not-authenticated'
      }`}>
      <Head>
        <title>Shift.ms</title>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width"
          key="viewport"
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/assets/images/shiftms_ogimage.png`}
          key="og:image"
        />
      </Head>
      <Script
        id="google-tag-manager"
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTAG_CONTAINER_ID}');
              `,
        }}
      />
      <Script
        id="google-tag-manager-consent"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer.push({
                "consent": {
                  ad_storage: "denied",
                  analytics_storage: "denied",
                  wait_for_update: 500
                }
              });
              `,
        }}
      />
      <SignupBanner />
      <TopNav
        mainMenuActive={mainMenuActive}
        setMainMenuActive={setMainMenuActive}
      />
      <MainMenu active={mainMenuActive} setActive={setMainMenuActive} />
      <Navbar />
      <div className="page">{children}</div>
      <WelcomeTour />
      <EmailVerfiyPromptDialog />
      {uid && !isAuthPath(router.pathname) && <ProfileBuilderLink />}
    </div>
  );
};

export default SiteLayout;
