import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  query,
  Timestamp,
  updateDoc,
  where,
  serverTimestamp,
} from 'firebase/firestore';
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from 'firebase/storage';
import { Hit } from '@algolia/client-search';
import { db, storage } from '@/firebase/firebase';
import search from '@/algolia/search';
import debouncePromise from '@/lib/debounce';
import { SuggestionDataItem } from 'react-mentions';
import { IPostFormData, IPostAdded } from '@/types/Collections/posts';
import { IUser, IProfile } from '@/types/Collections/users';
import { ISetting } from '@/types/Collections/settings';

export const postCreate = async (
  post: IPostFormData,
  uid: string,
  user: IUser,
  settings: ISetting,
): Promise<IPostAdded | false> => {
  const createdAtTimestamp = serverTimestamp();
  const postsCount = (user.postsCount || 0) + 1;
  const data = {
    ...post,
    author: {
      username: user.username,
      uid,
      profileImgUrl: user.profileImgUrlThumb || user.profileImgUrl || null,
      created: user.created
        ? user.created
        : Timestamp.fromDate(
            new Date(2020, 9, 1, 0, 0, 0, 0), // Arbitrary date but far enough in the past
          ),
      postsCount,
      lessThan3Posts: postsCount < 3,
    },
    created: createdAtTimestamp,
    isDeleted: false,
    reportsCount: 0,
    lastActive: createdAtTimestamp,
    modified: createdAtTimestamp,
    commentsCount: 0,
    platform: 'web',
  };
  const res: IPostAdded | false = await addDoc(collection(db, 'posts'), data)
    .then(docRef => ({ id: docRef.id, ...data } as IPostAdded))
    .catch(() => false);
  if (res) {
    if (!settings?.isNpsSeen) {
      const posts = await getDocs(
        query(
          collection(db, 'posts'),
          where('author.username', '==', user.username),
          where('isDeleted', '==', false),
          where('isPublished', '==', true),
          limit(2),
        ),
      );

      if (posts.docs.length === 1) {
        updateDoc(doc(db, 'settings', uid), {
          firstPostCreatedTime: createdAtTimestamp,
        });
      }
    }
  }
  return res;
};

export const postSave = async (
  postId: string,
  post: IPostFormData,
): Promise<boolean> => {
  const editedAt = new Date();
  const editedAtTimestamp = Timestamp.fromDate(editedAt);
  const postData = {
    ...post,
    edited: editedAtTimestamp,
    modified: editedAtTimestamp,
  };
  const result = updateDoc(doc(db, 'posts', postId), postData)
    .then(() => true)
    .catch(() => false);
  return result;
};

export const uploadImage = async (
  uploadFile: string,
  fileName: string,
  postId: string,
): Promise<boolean> => {
  const imageResponse = await fetch(uploadFile);
  const blob = await imageResponse.blob();
  const path = `posts/${postId}/${fileName}`;
  const storageRef = ref(storage, path);

  await uploadBytes(storageRef, blob);
  const url = await getDownloadURL(ref(storageRef));
  return updateDoc(doc(db, 'posts', postId), {
    image: url,
    imageName: fileName,
  })
    .then(() => true)
    .catch(() => false);
};

export const removeImage = async (
  imageName: string,
  postId: string,
): Promise<boolean> => {
  const imageRef = ref(storage, `posts/${postId}/${imageName}`);
  await deleteObject(imageRef);

  const imageThumbRef = ref(storage, `posts/${postId}/thumb_${imageName}.jpg`);
  await deleteObject(imageThumbRef);

  return updateDoc(doc(db, 'posts', postId), {
    image: null,
    imageName: null,
  })
    .then(() => true)
    .catch(() => false);
};

export const searchUsers = debouncePromise(
  async (query: string, callback: (data: SuggestionDataItem[]) => void) => {
    if (query !== '') {
      const searchIndex = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_USERS_INDEX;
      const page = 1;
      const res = await search(query, searchIndex, 6, page);
      const hits = res?.hits as Hit<IProfile>[];
      const data = hits.map(u => ({ display: u.username, id: u.uid }));
      callback(data);
    }
  },
  500,
);
