import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import firebaseConfig from './config';

export class Fuego {
  constructor(config) {
    this.db = !firebase.apps.length
      ? firebase.initializeApp(config).firestore()
      : firebase.app().firestore();
    this.auth = firebase.auth();
    this.functions = firebase.functions();
    this.storage = firebase.storage();
  }
}

const fuego = new Fuego(firebaseConfig);
export default fuego;
