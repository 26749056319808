// https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
import React, { useState } from 'react';

type UseInputReturnBindType = {
  value: string;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
};

export type UseInputReturnType = {
  value: string | any;
  setValue: React.Dispatch<React.SetStateAction<string | any>>;
  reset: () => void;
  bind: UseInputReturnBindType;
  bind1: UseInputReturnBindType;
  bind2: UseInputReturnBindType;
};

const useInput = (initialValue: string): UseInputReturnType => {
  const [value, setValue] = useState<string | any>(initialValue);

  const reset = () => setValue('');

  const bind = {
    value,
    onChange: (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLSelectElement>
        | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      setValue(event.target.value);
    },
  };

  return {
    value,
    setValue,
    reset,
    bind,
    bind1: bind,
    bind2: bind,
  };
};

export default useInput;
