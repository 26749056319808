import { mdiCheckCircle, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import React, { useState } from 'react';
import Image from 'next/image';
import { User, getAuth, sendEmailVerification } from 'firebase/auth';
import * as gtag from '../../lib/gtag';
import eventAdd from '../../lib/eventAdd';
import { useAuth } from '../../providers/AuthProvider';
import useSniperLink from '../../hooks/useSniperLink';

const EmailVerifyPrompt = (): JSX.Element => {
  const [isSent, setIsSent] = useState(false);
  const { uid, auth } = useAuth();
  const sniperLinkConfig = useSniperLink(auth?.email ? auth.email : null);

  const requestVerifyEmail = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const auth = getAuth();

    sendEmailVerification(auth.currentUser as User).then(() => {
      if (uid && auth?.currentUser?.email) {
        eventAdd({
          uid,
          eventType: 'VERIFY_EMAIL:REQUEST',
          eventSource: 'USER',
          eventSourceId: uid,
          meta: {},
          email: auth?.currentUser?.email,
          platform: 'web',
        });
      }
      setIsSent(true);
    });

    gtag.event({
      action: 'user.requestverify.click',
      params: {
        name: 'Request verify link',
      },
    });
  };

  if (isSent) {
    return (
      <div className="">
        <div className="has-text-centered">
          <h1 className="title icon-text">
            <span className="icon has-text-success is-medium">
              <Icon path={mdiCheckCircle} size={1.25} />
            </span>
            <span>Email sent</span>
          </h1>
          <p className="mb-4 is-font-brand">Please check your inbox.</p>
          {sniperLinkConfig && (
            <div className="buttons is-centered">
              <Link
                target="_blank"
                href={sniperLinkConfig.link}
                className="button is-rounded is-medium">
                <span className="mr-3 is-flex">
                  <Image
                    src={`${process.env.ASSET_PREFIX}/assets/svg/${sniperLinkConfig.provider}.svg`}
                    className="is-rounded"
                    alt={sniperLinkConfig.provider}
                    width={35}
                    height={35}
                  />
                </span>

                <span>{sniperLinkConfig.title}</span>
                <span className="ml-2">
                  <Icon path={mdiOpenInNew} size={0.75} color="grey" />
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="has-text-centered">
        <h1 className="title">👋 Hi there!</h1>
        <p className="mb-4 is-font-brand">
          We sent you an email to verify your email address. If you didn&apos;t
          receive it please click the button below.
        </p>
        <div className="buttons is-centered">
          <button
            type="button"
            onClick={requestVerifyEmail}
            className="button is-primary is-rounded">
            <span className="icon">
              <Icon path={mdiOpenInNew} size={1} />
            </span>
            <span>Request new verfication link</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerifyPrompt;
