export const getHoursOptions = () => {
  const hours = [
    <option value="" key="hours----">
      hh
    </option>,
  ];
  let i = 0;
  for (i; i <= 23; i += 1) {
    hours.push(
      <option value={i} key={i}>
        {i}
      </option>,
    );
  }
  return hours;
};

export const getMinutesOptions = () => {
  const minutes = [
    <option value="" key="minutes----">
      mm
    </option>,
  ];
  let i = 0;
  for (i; i < 60; i += 1) {
    minutes.push(
      <option value={i} key={i}>
        {i}
      </option>,
    );
  }
  return minutes;
};
