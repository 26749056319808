import {
  mdiAccount,
  mdiClose,
  mdiExitToApp,
  mdiFacebook,
  mdiInstagram,
  mdiLinkedin,
  mdiTwitter,
  mdiYoutube,
} from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useEffect, KeyboardEvent } from 'react';
import { useAuth } from '@/providers/AuthProvider';
import signoutUser from '@/providers/AuthProvider/lib/signoutUser';

const MainMenu = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: (active: boolean) => void;
}): JSX.Element => {
  const { isLoggedIn } = useAuth();
  const thisYear = new Date().getFullYear();

  useEffect(() => {
    if (active) {
      document.documentElement.classList.add('is-clipped');
    } else {
      document.documentElement.classList.remove('is-clipped');
    }
  }, [active]);

  const mainmenuClose = () => {
    setActive(false);
  };

  const handleEscPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setActive(false);
    }
  };

  return (
    <>
      <div
        onClick={mainmenuClose}
        className={`mainmenu-overlay is-overlay${active ? ' is-active' : ''}`}
        onKeyDown={handleEscPress}
        aria-hidden="true"
      />
      <div className={`mainmenu${active ? ' is-active' : ''}`}>
        <button
          type="button"
          aria-label="Close"
          onClick={mainmenuClose}
          className="button is-white mainmenu-close">
          <span className="icon">
            <Icon path={mdiClose} size={1} />
          </span>
        </button>
        <div className="mainmenu-content">
          <div className="mainmenu-section">
            <ul>
              <li>
                <Link href="/home" legacyBehavior>
                  <a
                    role="link"
                    tabIndex={0}
                    onClick={mainmenuClose}
                    onKeyDown={handleEscPress}>
                    Forum
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          <div className="mainmenu-section">
            <ul>
              <li className="mainmenu-section-item-bold">
                <a
                  href="https://shift.ms/learn-about-ms"
                  onClick={mainmenuClose}>
                  Learn about MS
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/learn-about-ms/ms-symptoms"
                  onClick={mainmenuClose}>
                  MS symptoms
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/learn-about-ms/ms-diagnosis"
                  onClick={mainmenuClose}>
                  MS diagnosis
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/learn-about-ms/ms-treatment"
                  onClick={mainmenuClose}>
                  MS treatment
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/learn-about-ms/living-with-ms"
                  onClick={mainmenuClose}>
                  Living with MS
                </a>
              </li>
              <li className="mainmenu-section-item-bold">
                <Link as="/find-msers" href="/find-msers" legacyBehavior>
                  <a
                    role="link"
                    tabIndex={0}
                    onClick={mainmenuClose}
                    onKeyDown={handleEscPress}>
                    Find MSers
                  </a>
                </Link>
              </li>
              <li className="mainmenu-section-item-bold">
                <a
                  href="https://shift.ms/the-buddy-network"
                  onClick={mainmenuClose}>
                  Buddy Network
                </a>
              </li>
              <li className="mainmenu-section-item-bold">
                <a href="https://shift.ms/films" onClick={mainmenuClose}>
                  Films
                </a>
              </li>
              <li className="mainmenu-section-item-bold">
                <a href="https://shift.ms/ms-latest" onClick={mainmenuClose}>
                  MS Latest
                </a>
              </li>
            </ul>
          </div>
          <div className="mainmenu-section">
            <ul>
              <li className="mainmenu-section-item-bold">
                <a href="https://shift.ms/get-involved" onClick={mainmenuClose}>
                  Get Involved
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/get-involved/volunteer"
                  onClick={mainmenuClose}>
                  Volunteer
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/get-involved/fundraise"
                  onClick={mainmenuClose}>
                  Fundraise
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/get-involved/donate"
                  onClick={mainmenuClose}>
                  Donate
                </a>
              </li>
            </ul>
          </div>
          <div className="mainmenu-section">
            <ul>
              <li className="mainmenu-section-item-bold">
                <a href="https://shift.ms/about-us" onClick={mainmenuClose}>
                  About us
                </a>
              </li>
              <li>
                <a href="https://shift.ms/support" onClick={mainmenuClose}>
                  Contact us
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/support/house-rules"
                  onClick={mainmenuClose}>
                  House rules
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/support/terms-of-use"
                  onClick={mainmenuClose}>
                  Terms of use
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/support/privacy-policy"
                  onClick={mainmenuClose}>
                  Privacy policy
                </a>
              </li>
              <li>
                <a
                  href="https://shift.ms/support/cookie-policy"
                  onClick={mainmenuClose}>
                  Cookie policy
                </a>
              </li>
            </ul>
          </div>
          <div className="mainmenu-section mainmenu-signin-link">
            {isLoggedIn ? (
              <button
                onClick={() => {
                  mainmenuClose();
                  signoutUser();
                }}
                type="button"
                className="button is-white is-medium is-fullwidth">
                <span className="icon">
                  <Icon path={mdiExitToApp} size={1} />
                </span>
                <span>Sign out</span>
              </button>
            ) : (
              <Link href="/signin" as="/signin" legacyBehavior>
                <a
                  role="link"
                  tabIndex={0}
                  onClick={() => mainmenuClose()}
                  onKeyDown={() => mainmenuClose()}
                  className="button is-white is-medium is-fullwidth">
                  <span className="icon">
                    <Icon path={mdiAccount} size={1} />
                  </span>
                  <span>Sign in</span>
                </a>
              </Link>
            )}
          </div>
          <div className="mainmenu-section mainmenu-social-links">
            <div className="control">
              <a
                aria-label="Facebook"
                title="Facebook"
                className="mainmenu-social-link social-link-facebook"
                href="https://www.facebook.com/shift.ms"
                target="_blank"
                rel="noreferrer">
                <Icon path={mdiFacebook} size={1.5} />
              </a>
              <a
                aria-label="YouTube"
                title="YouTube"
                className="mainmenu-social-link social-link-youtube"
                href="https://www.youtube.com/user/shiftdotms/featured"
                target="_blank"
                rel="noreferrer">
                <Icon path={mdiYoutube} size={1.5} />
              </a>
              <a
                aria-label="Twitter"
                title="Twitter"
                className="mainmenu-social-link social-link-twitter"
                href="https://twitter.com/shiftms"
                target="_blank"
                rel="noreferrer">
                <Icon path={mdiTwitter} size={1.5} />
              </a>
              <a
                aria-label="Instagram"
                title="Instagram"
                className="mainmenu-social-link social-link-instagram"
                href="https://instagram.com/shiftdotms"
                target="_blank"
                rel="noreferrer">
                <Icon path={mdiInstagram} size={1.5} />
              </a>
              <a
                aria-label="LinkedIn"
                title="LinkedIn"
                className="mainmenu-social-link social-link-linkedin"
                href="https://www.linkedin.com/company/shift.ms/"
                target="_blank"
                rel="noreferrer">
                <Icon path={mdiLinkedin} size={1.5} />
              </a>
            </div>
          </div>
          <div className="mainmenu-footer">
            <div className="mainmenu-footer-content">
              <p>
                <a href="https://shift.ms/get-involved/donate">
                  We are a charity and rely on donations
                </a>
              </p>
              <p>Charity Number: 1117194 (England and Wales)</p>
              <p>Registered Company: 06000961</p>
              <p>
                Registered address:
                <br />
                Shift.ms, Platform, New Station Street, LS1 4JB, United Kingdom
                <br />
                <br />
                London office:
                <br />
                Shift.ms, Somerset House, Strand, West Goods Entrance, London
                WC2R 1LA, United Kingdom
              </p>
            </div>
            <p className="has-text-centered mb-4">©{thisYear} Shift.ms</p>
          </div>
        </div>
      </div>
    </>
  );
};

MainMenu.propTypes = {
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
};

export default MainMenu;
