/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import {
  RemoteConfig,
  getRemoteConfig,
  isSupported as isRemoteConfigSupported,
} from 'firebase/remote-config';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY!,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN!,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL!,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID!,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID!,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID!,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID!,
};

const app = initializeApp(firebaseConfig);
// eslint-disable-next-line import/no-mutable-exports
let analytics: ReturnType<typeof getAnalytics>;

if (typeof window !== 'undefined') {
  isSupported().then(() => {
    analytics = getAnalytics(app);
  });
}

const getRemoteConfigurations = async (): Promise<RemoteConfig | undefined> => {
  let remoteConfig: ReturnType<typeof getRemoteConfig> | undefined;
  if (typeof window !== 'undefined') {
    const supported = await isRemoteConfigSupported();
    if (supported) {
      remoteConfig = getRemoteConfig(app);
    }
  }

  return remoteConfig;
};

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

export { analytics, functions, storage, db, auth, getRemoteConfigurations };
