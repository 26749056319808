import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import React from 'react';
import dynamic from 'next/dynamic';
import PostCreateForm from './PostCreateForm';

const Dialog = dynamic(() => import('@mui/material/Dialog'), {
  ssr: false,
});

const PostModal = ({ open, onClose }) => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 767;

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={onClose}>
      <div className="card post-create-dialog large-dialog">
        <div className="modal-header">
          <h2 id="post-create-dialog-title" className="is-size-4">
            Write a post
          </h2>
          <div className="buttons modal-close-button">
            <button
              type="button"
              aria-label="Close"
              onClick={onClose}
              className="button is-light"
            >
              <span className="icon">
                <Icon path={mdiClose} size={1.25} />
              </span>
            </button>
          </div>
        </div>
        <PostCreateForm onClose={onClose} />
      </div>
    </Dialog>
  );
};

PostModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PostModal;
