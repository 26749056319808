/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FuegoProvider } from '@nandorojo/swr-firestore';
import Router, { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import fuego from '@/firebase/fuego';
import * as gtag from '@/lib/gtag';
import AdProvider from '@/providers/AdProvider';
import AuthProvider from '@/providers/AuthProvider';
import NavProvider from '@/providers/NavProvider';
import SignupBannerProvider from '@/providers/SignupBannerProvider';
import NpsModal from '@/components/Nps/NpsModal';
import useHubspotTracking from '@/hooks/useHubspotTracking';
import SiteLayout from '@/components/Layout';
import '../styles.scss';

if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  let consented = false;
  if (window.Cookiebot) {
    consented = window.Cookiebot.consent.necessary;
  }
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.posthog.com',
    autocapture: false,
    loaded: ph => {
      if (process.env.NODE_ENV === 'development' || !consented)
        ph.opt_out_capturing();
    },
  });
  window.addEventListener('CookiebotOnAccept', function () {
    if (window.Cookiebot.consent.necessary) {
      posthog.opt_in_capturing();
    }
  });
}

const Shiftms5App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  useHubspotTracking(router?.asPath);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => {
      gtag.event({
        action: 'page_view',
        params: {
          page_title: document.title,
          page_path: Router.asPath,
        },
      });
      posthog?.capture('$pageview');
    };
    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <FuegoProvider fuego={fuego}>
      <PostHogProvider client={posthog}>
        <AuthProvider>
          <NpsModal />
          <NavProvider>
            <AdProvider>
              <SignupBannerProvider>
                <SnackbarProvider
                  maxSnack={3}
                  classes={{
                    containerRoot: 'snackbar',
                  }}>
                  <SiteLayout>
                    <Component {...pageProps} />
                  </SiteLayout>
                </SnackbarProvider>
              </SignupBannerProvider>
            </AdProvider>
          </NavProvider>
        </AuthProvider>
      </PostHogProvider>
    </FuegoProvider>
  );
};

export default Shiftms5App;
