import React from 'react';
import Dialog from '@mui/material/Dialog';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import {
  getDateOptions,
  getMonthOptions,
  getNextThreeYearOptions,
} from '@/lib/date';
import { getHoursOptions, getMinutesOptions } from '@/lib/time';
import useTimeInput from '@/hooks/timeInputHook';
import { Timestamp } from '@/types/Firebase/firebase';

const SchedulePostDialog = ({
  open,
  onClose,
  setScheduledDate,
  prevScheduledDate,
}: {
  open: boolean;
  onClose: () => void;
  setScheduledDate: (date: Date | null) => void;
  prevScheduledDate: Timestamp | null;
}): JSX.Element => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 767;

  const prevTime = prevScheduledDate
    ? new Date(
        prevScheduledDate?.toDate() ? prevScheduledDate?.toDate() : 0 * 1000,
      )
    : null;

  const {
    bindMinutes,
    bindHours,
    value,
    bindYear: bindDobYear,
    bindMonth: bindDobMonth,
    bindDay: bindDobDay,
    isDateValid,
    valid,
  } = useTimeInput(prevTime || new Date());

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const handleCancel = () => {
    setScheduledDate(null);
    onClose();
  };

  const handleConfirm = () => {
    setScheduledDate(value);
    onClose();
  };

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={onClose}>
      <div className="card post-create-dialog large-dialog">
        <div className="modal-header">
          <h2 id="post-create-dialog-title" className="is-size-4">
            Schedule
          </h2>
          <div className="buttons modal-close-button">
            <button
              type="button"
              aria-label="Close"
              onClick={onClose}
              className="button is-light">
              <span className="icon">
                <Icon path={mdiClose} size={1.25} />
              </span>
            </button>
          </div>
        </div>
        <div className="mx-4 mb-4">
          <p>Will post on {value.toLocaleString()}</p>
          <div className="field">
            <label htmlFor="onboarding-dob-day" className="label">
              Date
            </label>
            <div className="control is-grouped">
              <div className="select">
                <select id="onboarding-dob-day" {...bindDobDay}>
                  {getDateOptions()}
                </select>
              </div>
              <div className="select">
                <select {...bindDobMonth}>{getMonthOptions()}</select>
              </div>
              <div className="select">
                <select {...bindDobYear}>{getNextThreeYearOptions()}</select>
              </div>
            </div>
            {!isDateValid && valid && (
              <p className="help is-danger">
                You can’t schedule a post to send in the past.
              </p>
            )}
          </div>
          <div className="field">
            <label htmlFor="onboarding-dob-day" className="label">
              Time
            </label>
            <div className="control is-grouped">
              <div className="select">
                <select {...bindHours}>{getHoursOptions()}</select>
              </div>
              <div className="select">
                <select {...bindMinutes}>{getMinutesOptions()}</select>
              </div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="onboarding-dob-day" className="label">
              Time Zone
            </label>
            <p>{timeZone}</p>
          </div>
          <div className="post-form-footer is-clearfix">
            <div className="post-form-submit is-justify-content-space-between field is-grouped">
              <div className="control cancel-control">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="button is-light">
                  <span className="icon">
                    <Icon path={mdiClose} size={1} />
                  </span>
                  <span>Clear</span>
                </button>
              </div>

              <div className="control">
                <button
                  type="button"
                  onClick={handleConfirm}
                  className="button is-primary"
                  disabled={!valid || !isDateValid}>
                  <span>Confirm</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SchedulePostDialog;
