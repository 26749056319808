import PropTypes from 'prop-types';
import Image from 'next/image';
import { useEffect } from 'react';
import ShiftmsImgMob from '../../public/assets/images/nps/how-shiftms-started.png';
import ShiftmsImg from '../../public/assets/images/nps/how-shiftms-started-desktop.png';
import BuddyImgMob from '../../public/assets/images/nps/looking-for-ms-buds-mobile.png';
import BuddyImg from '../../public/assets/images/nps/looking-for-ms-buds-desktop.png';
import ExploreImgMob from '../../public/assets/images/nps/have-you-explore-shiftms-mobile.png';
import ExploreImg from '../../public/assets/images/nps/have-you-explore-shiftms-desktop.png';

const Thankyou = ({
  currentStep, setCurrentStep, npsData, setNpsData, username, handleClose,
}) => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 767;

  useEffect(() => {
    setNpsData({ ...npsData, complete: true });
  }, []);

  const bannerClick = (event, href) => {
    event.preventDefault();
    setNpsData({ ...npsData, exit: href });
    handleClose(event, href);
    window.location.href = href;
  };

  const renderContent = () => {
    switch (currentStep) {
      case 'hard':
        return (
          <div>
            <h2 className=" is-size-4 mb-4">
              Thank you @
              {username}
            </h2>
            <div className="is-font-brand">
              Sorry about that. Thanks for taking the time to give us feedback.
              It will help us to provide the best possible experience for the community.
            </div>
          </div>
        );

      case 'questions':
        return (
          <div>
            <h2 className="is-size-4 mb-4">
              Thank you
            </h2>
            <div className="is-font-brand mb-4">
              Thanks for your feedback @
              {username}
              .
            </div>
            <a href="https://shift.ms/explore" rel="noreferrer" className="nps-modal-img" onClick={(e) => bannerClick(e, 'https://shift.ms/explore')}>
              <Image src={isMobile ? ExploreImgMob : ExploreImg} alt="Looking for an MS buddy?" />
            </a>
          </div>
        );

      case 'people':
        return (
          <div>
            <h2 className="is-size-4 mb-4">
              Thank you
            </h2>
            <p className="is-font-brand mb-4">
              Thanks for your feedback @
              {username}
              .
            </p>
            <a href="https://shift.ms/the-buddy-network" rel="noreferrer" className="nps-modal-img" onClick={(e) => bannerClick(e, 'https://shift.ms/the-buddy-network')}>
              <Image src={isMobile ? BuddyImgMob : BuddyImg} alt="Looking for an MS buddy?" />
            </a>
          </div>
        );

      case 'feedbackthanks':
        return (
          <div>
            <h2 className=" is-size-4 mb-4 nps-card-header-desktop5 is-font-brand-bold MobileHeading">
              Thank you @
              {username}
            </h2>
            <p className="is-font-brand">
              Sorry about that. Thanks for taking the time to give us feedback.
              It will help us to provide the best possible experience for the community.
            </p>
          </div>
        );

      case 'thanks':
      default:
        return (
          <div>
            <h2 className=" is-size-4 mb-4 nps-card-header-desktop5 is-font-brand-bold MobileHeading">
              Thanks @
              {username}
            </h2>
            <p className="is-font-brand mb-4">
              It&apos;s great to hear your feedback, thanks for taking the time to share.
            </p>
            <a href="https://shift.ms/about-us" rel="noreferrer" className="nps-modal-img" onClick={(e) => bannerClick(e, 'https://shift.ms/about-us')}>
              <Image src={isMobile ? ShiftmsImgMob : ShiftmsImg} alt="Have you heard how Shift.ms started?" />
            </a>
          </div>
        );
    }
  };
  return (
    <>
      {currentStep === 'thanks' ? (
        <div className="nps-modal-background-grey-circle" />
      ) : (
        <>
          <div className="nps-modal-background-blue-circle" />
          <div className="nps-modal-background nps-modal-background-right" />

        </>
      )}
      <div className="nps-modal-content-center">
        {renderContent()}
      </div>
      <div className="buttons">
        <button
          className="button is-light is-normal feedback-submit mt-3"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            if (currentStep === 'thanks') {
              setCurrentStep('score');
            } else if (currentStep === 'feedbackthanks') {
              setCurrentStep('other');
            } else {
              setCurrentStep('feedback');
            }
          }}
        >
          Back
        </button>
        <button
          className="button is-primary is-normal feedback-submit mt-3"
          type="submit"
          onClick={(e) => handleClose(e, 'closed')}
        >
          Close
        </button>
      </div>
    </>
  );
};

Thankyou.propTypes = {
  currentStep: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setNpsData: PropTypes.func.isRequired,
  npsData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

export default Thankyou;
