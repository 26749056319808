import { User } from 'firebase/auth';
import { IAuthState, IAuthContextState } from './types';
import { isAdminAccount } from './utils';

export enum AuthActionTypes {
  AUTH_LOADED = 'AUTH_LOADED',
  AUTH_LOADING = 'AUTH_LOADING',
  SET_UNSEEN_NOTIFICATIONS_COUNT = 'SET_UNSEEN_NOTIFICATIONS_COUNT',
  SET_AUTH_RESULT = 'SET_AUTH_RESULT',
  SET_SIGNED_OUT = 'SET_SIGNED_OUT',
  SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED',
  SHOW_VERIFY_EMAIL_DIALOG = 'SHOW_VERIFY_EMAIL_DIALOG',
  HIDE_VERIFY_EMAIL_DIALOG = 'HIDE_VERIFY_EMAIL_DIALOG',
}

export type AuthLoaded = {
  type: 'AUTH_LOADED';
};

export type AuthLoading = {
  type: 'AUTH_LOADING';
};

export type SetAuthResult = {
  type: 'SET_AUTH_RESULT';
  payload: {
    authResult: User | null;
  };
};

export type SetSignedOut = {
  type: 'SET_SIGNED_OUT';
};

export type SetEmailVerified = {
  type: 'SET_EMAIL_VERIFIED';
};

export type SetUnseenNotificaitonsCount = {
  type: 'SET_UNSEEN_NOTIFICATIONS_COUNT';
  payload: {
    count: number;
  };
};

export type ShowVerifyEmailDialog = {
  type: 'SHOW_VERIFY_EMAIL_DIALOG';
};

export type HideVerifyEmailDialog = {
  type: 'HIDE_VERIFY_EMAIL_DIALOG';
};

export const authInitialState: IAuthState = {
  authLoaded: false,
  isLoggedIn: false,
  isAdmin: false,
  isVerified: false,
  uid: null,
  auth: null,
  user: null,
  settings: null,
  unseenNotificationsCount: 0,
  setUnseenNotificationsCount: () => {},
  isVerifyEmailDialogOpen: false,
  showVerifyEmailDialog: () => {},
  hideVerifyEmailDialog: () => {},
};

export const authReducer = (
  state: IAuthContextState,
  action:
    | AuthLoaded
    | AuthLoading
    | SetAuthResult
    | SetSignedOut
    | SetEmailVerified
    | SetUnseenNotificaitonsCount
    | ShowVerifyEmailDialog
    | HideVerifyEmailDialog,
): IAuthContextState => {
  switch (action.type) {
    case AuthActionTypes.AUTH_LOADED:
      return {
        ...state,
        authLoaded: true,
      };

    case AuthActionTypes.AUTH_LOADING:
      return {
        ...state,
        authLoaded: false,
      };

    case AuthActionTypes.SET_AUTH_RESULT:
      if (action.payload.authResult) {
        return {
          ...state,
          isLoggedIn: true,
          isAdmin: isAdminAccount(action.payload.authResult),
          isVerified: !!action.payload.authResult.emailVerified,
          uid: action.payload.authResult.uid,
          auth: action.payload.authResult,
        };
      }
      return {
        ...authInitialState,
        authLoaded: true,
      };

    case AuthActionTypes.SET_SIGNED_OUT:
      return {
        ...authInitialState,
        authLoaded: true,
      };

    case AuthActionTypes.SET_EMAIL_VERIFIED:
      return {
        ...state,
        isVerified: true,
      };

    case AuthActionTypes.SET_UNSEEN_NOTIFICATIONS_COUNT:
      return {
        ...state,
        unseenNotificationsCount: action.payload.count,
      };

    case AuthActionTypes.SHOW_VERIFY_EMAIL_DIALOG:
      return {
        ...state,
        isVerifyEmailDialogOpen: true,
      };

    case AuthActionTypes.HIDE_VERIFY_EMAIL_DIALOG:
      return {
        ...state,
        isVerifyEmailDialogOpen: false,
      };

    default:
      return state;
  }
};
