import React from 'react';
import PostForm from './PostForm';

const PostCreateForm = ({ onClose }: { onClose: () => void }): JSX.Element => (
  <div className="post-create-form is-clearfix">
    <PostForm onClose={onClose} />
  </div>
);

export default PostCreateForm;
