import Image from 'next/image';
import Link from 'next/link';
import { useMemo } from 'react';
import usePreviousValue from '@/hooks/usePrevious';

const Avatar = ({
  imageUrl = null,
  username = null,
  size = false,
  showUsername = false,
  isUploading = false,
  linkIt = false,
}: {
  imageUrl: string | null;
  username: string | null;
  size: number | false;
  showUsername: boolean;
  isUploading: boolean;
  linkIt: boolean;
}): JSX.Element => {
  const prevUploadingStatus = usePreviousValue(false);

  if (!imageUrl) {
    imageUrl = `${process.env.ASSET_PREFIX}/assets/svg/avatar.svg`;
  } else if (!/^https?:\/\//i.test(imageUrl)) {
    imageUrl = `https://${imageUrl}`;
  }
  const className = 'is-rounded avatar';

  const randomKey = useMemo(() => {
    if (!isUploading && prevUploadingStatus !== isUploading) {
      return Math.random();
    }
  }, [isUploading, prevUploadingStatus]);

  let img = (
    <Image
      key={randomKey}
      src={imageUrl}
      width={92}
      height={92}
      className={`${className}${isUploading ? ' loading-avatar' : ''}`}
      alt={`@${username ? username : 'username'}`}
      blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVR42mOsO7K4noEIwDiqkL4KAd9DF/MNJirvAAAAAElFTkSuQmCC"
      placeholder="blur"
    />
  );

  if (size) {
    img = (
      <figure
        className={`avatar-image image is-${size}x${size}${
          isUploading ? ' loading-avatar' : ''
        }`}>
        <Image
          src={imageUrl || ''}
          width={size}
          height={size}
          className={className}
          alt={username || ''}
          blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVR42mOsO7K4noEIwDiqkL4KAd9DF/MNJirvAAAAAElFTkSuQmCC"
          placeholder="blur"
        />
      </figure>
    );
  }

  if (linkIt) {
    return (
      <Link legacyBehavior href="/[...slug]" as={`/@${username}`}>
        <a className="avatar-link">
          {img}
          {showUsername ? (
            <span className="tag is-white">@{username}</span>
          ) : (
            ''
          )}
        </a>
      </Link>
    );
  }
  if (showUsername) {
    return (
      <>
        {img}
        <span className="tag is-white">@{username}</span>
      </>
    );
  }
  return img;
};

Avatar.defaultProps = {
  imageUrl: null,
  username: null,
  size: false,
  showUsername: false,
  isUploading: false,
  linkIt: false,
};

export default Avatar;
