import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { functions } from '../firebase/firebase';

interface IEventDefaultsReq {
  uid: string;
  eventType: string;
  eventSource: string;
  eventSourceId?: string;
  email?: string | null;
  meta: any;
  platform?: 'web' | 'ios' | 'android' | null;
}

const eventAdd = async ({
  uid,
  eventType,
  eventSource,
  eventSourceId,
  meta,
  email = null,
  platform = 'web',
}: IEventDefaultsReq): Promise<HttpsCallableResult | undefined> => {
  try {
    if (uid && eventType && eventSource && eventSourceId) {
      const eventAddFunction = httpsCallable(
        functions,
        'eventCallable-eventAdd',
      );
      return eventAddFunction({
        uid,
        eventType,
        eventSource,
        eventSourceId,
        meta,
        email,
        platform,
      });
    }
  } catch (err) {
    return undefined;
  }
};

export default eventAdd;
