export const getYearOptions = (): JSX.Element[] => {
  const currentYear = parseInt(new Date().getFullYear().toString(), 10);
  let i = currentYear - 16;
  const years = [
    <option value="" key="year----">
      yyyy
    </option>,
  ];
  for (i; i >= currentYear - 100; i -= 1) {
    years.push(
      <option value={i} key={i}>
        {i}
      </option>,
    );
  }
  return years;
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getMonthOptions = (): JSX.Element[] => {
  const months = [
    <option value="" key="month--">
      mm
    </option>,
  ];
  let i = 0;
  for (i; i < 12; i += 1) {
    months.push(
      <option value={i} key={i}>
        {monthNames[i]}
      </option>,
    );
  }
  return months;
};

export const getDateOptions = (): JSX.Element[] => {
  const days = [
    <option value="" key="day--">
      dd
    </option>,
  ];
  let i = 1;
  for (i; i <= 31; i += 1) {
    days.push(
      <option value={i} key={i}>
        {i}
      </option>,
    );
  }
  return days;
};

export const getNextThreeYearOptions = (): JSX.Element[] => {
  const currentYear = parseInt(new Date().getFullYear().toString(), 10);
  let i = currentYear;
  const years = [
    <option value="" key="year----">
      yyyy
    </option>,
  ];
  for (i; i < currentYear + 3; i += 1) {
    years.push(
      <option value={i} key={i}>
        {i}
      </option>,
    );
  }
  return years;
};
