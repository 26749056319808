import useSWR, { SWRResponse } from 'swr';
import { ISetting } from '@/types/Collections/settings';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import { AuthErrorResponse, AuthSuccessResponse } from '../lib/authResponse';
import { IAuthResponse } from '../types';

const fetcher = async (path: string): Promise<ISetting | null> => {
  const docRef = doc(db, path);
  return getDoc(docRef)
    .then(doc => {
      if (doc.exists()) {
        const settings = doc.data() as ISetting;
        return settings;
      }
      return null;
    })
    .catch(() => null);
};

export const updateSettings = async (
  uid: string,
  settingsObj: Partial<ISetting>,
): Promise<IAuthResponse> => {
  return updateDoc(doc(db, 'settings', uid), settingsObj)
    .then(() => {
      return new AuthSuccessResponse();
    })
    .catch(err => {
      return new AuthErrorResponse(err);
    });
};

const useSettings = (uid: string | null): SWRResponse<ISetting | null> => {
  return useSWR(
    () => (uid ? `settings/${uid}` : null),
    key => fetcher(key),
  );
};

export default useSettings;
