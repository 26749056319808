import { mdiClose, mdiMapMarker, mdiMapMarkerOff, mdiClock } from '@mdi/js';
import Icon from '@mdi/react';
import { revalidateCollection } from '@nandorojo/swr-firestore';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { Mention, MentionsInput } from 'react-mentions';
import { v4 as uuidv4 } from 'uuid';
import useCharacterLimitInput from '@/hooks/characterLimitInputHook';
import useInput from '@/hooks/inputHook';
import useLocation from '@/hooks/useLocation';
import * as gtag from '@/lib/gtag';
import eventAdd from '@/lib/eventAdd';
import { postUrl } from '@/lib/utils';
import { useAuth } from '@/providers/AuthProvider';
import Avatar from '@/components/Profile/Avatar';
import EmailVerifyPrompt from '@/components/Profile/EmailVerifyPrompt';
import {
  postCreate,
  postSave,
  uploadImage,
  removeImage,
  searchUsers,
} from './api';
import mentionStyles from './styles';
import {
  IPostFormData,
  IPost,
  IPostLocation,
  PostPollOption,
} from '@/types/Collections/posts';
import PostFormImageUpload from './PostFormImageUpload';
import PostFormPoll from './PostFormPoll';
import PostFormSchedulePostDialog from './PostFormSchedulePostDialog';

type PlaceInput = {
  label: string;
  value: {
    description: string;
  };
};

const PostForm = ({
  post = null,
  onClose,
}: {
  post?: IPost | null;
  onClose: () => void;
}): JSX.Element | null => {
  const router = useRouter();
  const { uid, user, isVerified, isAdmin, settings, auth } = useAuth();
  const userLocation = useLocation();
  const titleInput = useRef<HTMLInputElement>(null);

  const {
    value: title,
    bind: bindTitle,
    reset: resetTitle,
    characterCount: characterCountTitle,
  } = useCharacterLimitInput(post && post.title ? post.title : '', 70);

  const {
    value: content,
    bind: bindContent,
    reset: resetContent,
  } = useInput(post && post.content ? post.content : '');

  const [isFormValid, setFormValid] = useState(false);

  const [geoInfo, setGeoInfo] = useState<IPostLocation | null>(null);
  const [geoInfoOn, setGeoInfoOn] = useState(
    post && !post.geoInfo ? false : true,
  );
  const [place, setPlace] = useState<PlaceInput | null>(null);

  const [message, setMessage] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(
    post && post.image ? post.image : null,
  );
  const [imageFileName, setImageFileName] = useState<string | null>(null);

  const [isSchedulePostModalOpen, setSchedulePostModalOpen] = useState(false);
  const [scheduleDate, setScheduledDate] = useState<Date | null>(
    post && post.scheduleDate ? post.scheduleDate : null,
  );

  const [isPoll, setIsPoll] = useState(false);
  const { value: pollQuestion, bind: bindPollQuestion } = useInput(
    post && post?.poll?.question ? post?.poll?.question : '',
  );
  const [pollOptions, setPollOptions] = useState<PostPollOption[]>([
    {
      placeholder: 'Option 1',
      id: uuidv4(),
      value: '',
      isOptional: false,
      vote: 0,
    },
    {
      placeholder: 'Option 2',
      id: uuidv4(),
      value: '',
      isOptional: false,
      vote: 0,
    },
  ]);

  useEffect(() => {
    if (titleInput.current) {
      titleInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (post?.geoInfo?.location) {
      setPlace({
        label: post?.geoInfo?.location,
        value: { description: post?.geoInfo?.location },
      });
      setGeoInfo(post?.geoInfo);
    }
  }, [post?.geoInfo]);

  useEffect(() => {
    if (geoInfoOn && !place && userLocation) {
      if (
        userLocation.city &&
        userLocation.country &&
        userLocation.lat &&
        userLocation.lon
      ) {
        setGeoInfo({
          location: `${userLocation.city}, ${userLocation.country}`,
          lat: userLocation.lat,
          lng: userLocation.lon,
        });
        setPlace({
          label: `${userLocation.city}, ${userLocation.country}`,
          value: {
            description: `${userLocation.city}, ${userLocation.country}`,
          },
        });
      }
    }
  }, [place, userLocation, geoInfoOn]);

  useEffect(() => {
    if (post?.poll) {
      setPollOptions(post?.poll?.options);
    }
  }, [post?.poll]);

  const handleLocationChange = async (location: PlaceInput) => {
    setPlace(location);
    const results = await geocodeByAddress(location.value.description);
    const latLng = await getLatLng(results[0]);
    setGeoInfo({
      location: location.label,
      ...latLng,
    });
  };

  useEffect(() => {
    if (
      (isPoll &&
        title !== '' &&
        pollOptions[0].value !== '' &&
        pollOptions[1].value !== '' &&
        pollQuestion !== '') ||
      (isPoll === false && title !== '' && content !== '')
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [title, content, pollOptions, isPoll, pollQuestion]);

  const handleAddPoll = () => {
    setIsPoll(true);
  };

  const toggleGeoLocationOn = () => {
    if (geoInfoOn) {
      setPlace(null);
    } else if (userLocation) {
      handleLocationChange({
        label: `${userLocation.city}, ${userLocation.country}`,
        value: { description: `${userLocation.city}, ${userLocation.country}` },
      });
    }
    setGeoInfoOn(!geoInfoOn);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = URL.createObjectURL(e.target.files[0]);
      setImage(file);
      setImageFileName(uuidv4());
    }
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isFormValid) {
      const postData = {
        title,
        content,
        geoInfo: null,
        isPublished: true,
        author: {
          uid,
          username: user?.username,
          profileImgUrl:
            user?.profileImgUrlThumb || user?.profileImgUrl || null,
        },
      } as IPostFormData;
      if (isPoll) {
        postData.poll = {
          question: pollQuestion,
          options: pollOptions,
        };
      }
      if (scheduleDate) {
        postData.scheduleDate = scheduleDate;
        postData.isScheduled = true;
        postData.isPublished = false;
      }

      if (geoInfoOn) {
        postData.geoInfo = geoInfo;
      }

      try {
        if (post && post.id) {
          try {
            if (image && imageFileName) {
              if (post.imageName) await removeImage(post.imageName, post.id);
              await uploadImage(image, imageFileName, post.id);
            } else if (post.imageName)
              await removeImage(post.imageName, post.id);
          } catch (e) {
            // console.log('Something went wrong');
          }
          await postSave(post.id, postData);
          gtag.event({
            action: 'post.edit',
            params: {
              postId: post.id,
              postTitle: postData.title,
              bodyLength: postData.content.length,
              location: geoInfoOn,
              image: false, // TODO when we have image uploads in place
              uid,
              postsCount: user?.postsCount ? user.postsCount + 1 : 1,
            },
          });
          if (post.isPublished) {
            router.push('/[...slug]', postUrl(post));
          } else {
            setMessage(
              'Your post has been saved. It will be reviewed by a moderator prior to being published.',
            );
          }
        } else {
          if (uid && user && settings) {
            const newPost = await postCreate(postData, uid, user, settings);
            if (newPost) {
              if (image && newPost?.id && imageFileName) {
                await uploadImage(image, imageFileName, newPost.id);
              }

              if (isPoll) {
                eventAdd({
                  uid,
                  eventType: 'POLL:CREATE',
                  eventSource: 'POLL',
                  eventSourceId: newPost.id,
                  meta: {},
                  email: auth?.email,
                  platform: 'web',
                });
              }
              eventAdd({
                uid,
                eventType: 'POST:SUBMIT',
                eventSource: 'POST',
                eventSourceId: newPost.id,
                meta: {
                  postId: newPost.id,
                  postTitle: postData.title,
                  bodyLength: postData.content.length,
                  location: geoInfoOn,
                  uid,
                  postsCount: user?.postsCount ? user.postsCount + 1 : 1,
                },
                email: auth?.email,
                platform: 'web',
              });

              gtag.event({
                action: 'post.new',
                params: {
                  postId: newPost.id,
                  postTitle: postData.title,
                  bodyLength: postData.content.length,
                  location: geoInfoOn,
                  image: false, // TODO when we have image uploads in place
                  uid,
                  postsCount: user?.postsCount ? user.postsCount + 1 : 1,
                },
              });
            }
          }
          resetTitle();
          resetContent();
          setFormValid(false);
          onClose();
          revalidateCollection('posts');
        }
      } catch (e) {
        // console.log('Error: Post Create.');
      }
    }
  };

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (post && post.id) {
      router.push('/[...slug]', postUrl(post));
    } else {
      resetTitle();
      resetContent();
      setFormValid(false);
      gtag.event({
        action: 'post.cancel',
      });
      onClose();
    }
  };

  if (uid && !isVerified) {
    return (
      <div className="box">
        <EmailVerifyPrompt />
      </div>
    );
  }

  if (!uid) {
    return null;
  }

  return (
    <div className="post-form is-clearfix is-active">
      {message ? <div className="notification is-info">{message}</div> : ''}
      <form onSubmit={handleSubmit}>
        <div className="media">
          <figure className="media-left is-hidden-mobile">
            <div className="image is-64x64">
              <Avatar
                imageUrl={user?.profileImgUrlThumb || user?.profileImgUrl}
                username={user?.username}
                size={64}
              />
            </div>
          </figure>
          <div className="media-content">
            <div className="field">
              <div className="control">
                <input
                  className="input is-medium post-form-title-input"
                  placeholder="Share a short title"
                  autoComplete="off"
                  id="post-form-title"
                  ref={titleInput}
                  {...bindTitle}
                />
              </div>
              <p className="help has-text-right">
                {characterCountTitle}
                /70
              </p>
            </div>
            <div className="post-form-collapsable">
              <div className="field">
                <div className="control">
                  <MentionsInput
                    classNames={{
                      textarea__input: 'textarea m-0',
                    }}
                    style={mentionStyles}
                    placeholder="Need some advice? Want to get something off your chest? Celebrating a win? We're listening."
                    onChange={event =>
                      bindContent.onChange(
                        event as React.ChangeEvent<HTMLTextAreaElement>,
                      )
                    }
                    value={bindContent.value}>
                    <Mention
                      appendSpaceOnAdd
                      displayTransform={(id, display) => `@${display}`}
                      trigger="@"
                      data={searchUsers}
                      markup="@__display__ "
                      className="is-hidden"
                    />
                  </MentionsInput>
                </div>
              </div>

              {image && (
                <div
                  className="shiftms-url-image image is-16by9 field"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
              )}

              {isAdmin && (
                <div className="control is-flex is-justify-content-flex-end">
                  <PostFormImageUpload
                    image={image}
                    handleImageUpload={handleImageUpload}
                    resetImage={() => setImage(null)}
                  />
                  {!post?.poll && !isPoll && (
                    <button
                      type="button"
                      className="button is-light mb-2 is-small ml-2"
                      onClick={handleAddPoll}>
                      <span>Add Poll</span>
                    </button>
                  )}
                </div>
              )}

              {isPoll || post?.poll ? (
                <PostFormPoll
                  pollLocked={!!post?.poll}
                  pollOptions={pollOptions}
                  setPollOptions={setPollOptions}
                  bindPollQuestion={bindPollQuestion}
                />
              ) : null}

              {process.env.NEXT_PUBLIC_FIREBASE_API_KEY ? (
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <fieldset disabled={!geoInfoOn}>
                      <GooglePlacesAutocomplete
                        apiKey={process.env.NEXT_PUBLIC_FIREBASE_API_KEY}
                        selectProps={{
                          placeholder: geoInfoOn
                            ? 'Type a location...'
                            : 'Location sharing off',
                          value: place,
                          onChange: handleLocationChange,
                        }}
                      />
                    </fieldset>
                  </div>
                  <div className="control">
                    <button
                      type="button"
                      className={`button is-light ${
                        geoInfoOn ? 'is-info' : 'is-danger'
                      }`}
                      onClick={toggleGeoLocationOn}>
                      <span className="icon">
                        {geoInfoOn ? (
                          <Icon path={mdiMapMarker} size={1} />
                        ) : (
                          <Icon path={mdiMapMarkerOff} size={1} />
                        )}
                      </span>
                      <span className="is-hidden-mobile">
                        {geoInfoOn ? 'Location on' : 'Location off'}
                      </span>
                    </button>
                  </div>
                </div>
              ) : null}

              <div className="post-form-footer is-clearfix">
                <div className="post-form-submit field is-grouped">
                  <div className="control cancel-control">
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="button is-light">
                      <span className="icon">
                        <Icon path={mdiClose} size={1} />
                      </span>
                      <span>Cancel</span>
                    </button>
                  </div>
                  <div className="control is-flex is-align-items-center">
                    {isAdmin && (
                      <button
                        type="button"
                        className="mr-3 button"
                        onClick={() => setSchedulePostModalOpen(true)}>
                        <span className="icon">
                          <Icon
                            path={mdiClock}
                            className="is-primary has-text-grey"
                            size={1.3}
                          />
                        </span>
                      </button>
                    )}

                    <button
                      type="submit"
                      className="button is-primary"
                      disabled={!isFormValid}>
                      <span>
                        {scheduleDate && 'Schedule '}
                        {post && post.id ? 'Save' : 'Post'}
                      </span>
                    </button>
                  </div>
                </div>
                <PostFormSchedulePostDialog
                  prevScheduledDate={post?.scheduleDate}
                  setScheduledDate={setScheduledDate}
                  open={isSchedulePostModalOpen}
                  onClose={() => setSchedulePostModalOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PostForm;
