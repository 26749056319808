const Styles = {
  '&multiLine': {
    control: {
      minHeight: '128px',
    },
    highlighter: {
      minHeight: '48px',
      maxHeight: '128px',
    },
    input: {
      resize: 'vertical',
      overflow: 'auto',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#7EC4A3',
      },
    },
  },
};
export default Styles;
