import { isSupported, logEvent, Analytics } from 'firebase/analytics';
import { analytics } from '../firebase/firebase';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface EventParams {
  [key: string]: any;
}

interface Event {
  action: string;
  params?: EventParams;
}

export const event = async ({ action, params }: Event): Promise<void> => {
  try {
    window.dataLayer.push({
      event: action,
      details: params,
    });
    if (await isSupported()) {
      logEvent(analytics as Analytics, action, params);
    }
    // console.log(window.dataLayer);
  } catch (e) {
    // console.log('err: firebase event log', e);
  }
};

export const pushData = (key: string, data: unknown): void => {
  window.dataLayer.push({
    [key]: data,
  });
};
