import { mdiBellOutline, mdiEmailOutline, mdiHome, mdiPlusBox } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import {
  collection,
  query,
  where,
  limit,
  onSnapshot,
} from 'firebase/firestore';
import useSWRSubscription from 'swr/subscription';
import { connect } from 'getstream';
import { db } from '@/firebase/firebase';
import isAuthPath from '@/lib/authPaths';
import { useAuth } from '@/providers/AuthProvider';
import { useNav } from '@/providers/NavProvider';
import Avatar from '@/components/Profile/Avatar';
import PostModal from '@/components/Stream/PostModal';

const streamClient = connect(
  process.env.NEXT_PUBLIC_STREAM_API_KEY,
  null,
  process.env.NEXT_PUBLIC_STREAM_APP_ID,
);

const Navbar = (): JSX.Element | null => {
  const router = useRouter();
  const {
    authLoaded,
    isLoggedIn,
    uid,
    user,
    unseenNotificationsCount,
    setUnseenNotificationsCount,
  } = useAuth();
  const { isPostModalOpen, setPostModalOpen } = useNav();

  const { data: unreadInbox } = useSWRSubscription(
    () => (uid ? `userInbox/${uid}` : null),
    (key, { next }) => {
      const q = query(
        collection(db, `userChatrooms/${uid}/chatrooms`),
        where('isRead', '==', false),
        limit(51),
      );
      const unsubscribe = onSnapshot(
        q,
        querySnapshot => {
          next(null, querySnapshot.size);
        },
        err => {
          next(err, 0);
        },
      );
      return () => unsubscribe();
    },
  );

  useEffect(() => {
    if (
      uid &&
      typeof user?.streamAccessToken === 'string' &&
      user?.streamAccessToken.length > 0
    ) {
      const notificationFeed = streamClient.feed(
        'notification',
        uid,
        user.streamAccessToken,
      );
      notificationFeed
        .get({ limit: 50, mark_seen: false })
        .then(streamResponse => {
          if (streamResponse.unseen) {
            setUnseenNotificationsCount(streamResponse.unseen);
          }
        });

      const subscription = notificationFeed.subscribe(data => {
        // console.log(data);
        if (data.new.length) {
          setUnseenNotificationsCount(
            unseenNotificationsCount + data.new.length,
          );
        }
      });

      return () => {
        Promise.all([subscription]).then(([sub]) => {
          if (sub && typeof sub.cancel === 'function') {
            sub.cancel();
          }
        });
      };
    }
  }, [
    uid,
    user?.streamAccessToken,
    unseenNotificationsCount,
    setUnseenNotificationsCount,
  ]);

  if (!authLoaded) {
    return null;
  }

  if (authLoaded && !isLoggedIn) {
    return null;
  }

  if (isAuthPath(router.pathname)) {
    return null;
  }

  return (
    <nav className="site-nav" role="navigation" aria-label="main navigation">
      <div className="site-nav-buttons">
        <Link href="/home" legacyBehavior>
          <a
            className={`nav-home ${
              router.pathname === '/home' ? 'is-active' : ''
            }`}>
            <span className="icon">
              <Icon path={mdiHome} size={1} />
            </span>
            <span className="nav-label">Home</span>
          </a>
        </Link>
        <Link href="/notifications" legacyBehavior>
          <a
            id="nav-notification-icon"
            className={`nav-notifications ${
              unseenNotificationsCount > 0 ? 'has-notification' : ''
            }${router.pathname === '/notifications' ? ' is-active' : ''}`}>
            <span className="icon">
              <Icon path={mdiBellOutline} size={1} />
            </span>
            <span className="nav-label">Notifications</span>
            {unseenNotificationsCount > 0 ? (
              <span className="tag nav-count">{unseenNotificationsCount}</span>
            ) : (
              ''
            )}
          </a>
        </Link>
        <button
          type="button"
          tabIndex={0}
          onClick={() => setPostModalOpen(true)}
          onKeyDown={() => setPostModalOpen(true)}
          className="button nav-post">
          <span className="icon">
            <Icon path={mdiPlusBox} size={1} />
          </span>
          <span className="nav-label">Write a post</span>
        </button>
        <Link href="/inbox" legacyBehavior>
          <a
            id="inbox-notification-icon"
            className={`nav-inbox ${unreadInbox > 0 ? 'has-notification' : ''}${
              router.pathname.substring(0, 6) === '/inbox' ? ' is-active' : ''
            }`}>
            <span className="icon">
              <Icon path={mdiEmailOutline} size={1} />
            </span>
            <span className="nav-label">Inbox</span>
            {unreadInbox > 0 ? (
              <span className="tag nav-count">
                {unreadInbox > 50 ? '50+' : unreadInbox}
              </span>
            ) : (
              ''
            )}
          </a>
        </Link>
        {isLoggedIn && user ? (
          <Link as={`/@${user.username}`} href="/[...slug]" legacyBehavior>
            <a className="nav-user">
              <figure className="image is-48x48">
                <Avatar
                  imageUrl={user.profileImgUrlThumb || user.profileImgUrl}
                  username={user.username}
                  size={48}
                />
              </figure>
              <span className="nav-label">Profile</span>
            </a>
          </Link>
        ) : (
          <div className="nav-user">
            <figure className="image is-48x48">
              <Avatar />
            </figure>
          </div>
        )}
      </div>
      <PostModal
        open={isPostModalOpen}
        onClose={() => setPostModalOpen(false)}
      />
    </nav>
  );
};

export default Navbar;
