import { FirebaseError } from 'firebase-admin';

export const isAFirebaseError = (err: unknown): err is FirebaseError => {
  return (err as FirebaseError).code !== undefined;
};

export const isAAuthError = (err: unknown): err is AuthError => {
  return (err as AuthError).type === 'AuthError';
};

export class AuthError extends Error {
  code: string;
  type: string;

  constructor(code: string, message: string) {
    super(message);
    this.code = code;
    this.type = 'AuthError';
  }
}

export class AuthSuccessResponse {
  success: true;
  error: null;
  email: string | null;

  constructor(email: string | null = null) {
    this.success = true;
    this.error = null;
    this.email = email;
  }
}

export class AuthErrorResponse {
  success: false;
  error: FirebaseError | AuthError | null;

  constructor(err: unknown) {
    this.success = false;
    if (isAFirebaseError(err)) {
      this.error = err;
    } else if (isAAuthError(err)) {
      this.error = err;
    } else if (err instanceof Error) {
      this.error = new AuthError('unknown', err.message);
    } else if (typeof err === 'string') {
      this.error = new AuthError('unknown', err);
    } else {
      this.error = new AuthError('unknown', 'An error has occurred');
    }
  }
}
