import { useRouter } from 'next/router';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import parseUrl from '@/lib/routes';

interface BackLink {
  href: string;
  as: string;
  options: {
    shallow: boolean;
    scroll: boolean;
  };
}

interface INavProviderProps {
  children: React.ReactNode;
}

interface NavContextInterface {
  backLink: BackLink | null;
  isPostModalOpen: boolean;
  setPostModalOpen: (isOpen: boolean) => void;
}

export const NavContext = createContext<NavContextInterface | undefined>(
  undefined,
);

const NavProvider: React.FC<INavProviderProps> = props => {
  const router = useRouter();

  const [history, setHistory] = useState<string[]>([]);
  const [prevPath, setPrevPath] = useState<string | boolean>(false);
  const [backLink, setBackLink] = useState<BackLink | null>(null);
  const [isPostModalOpen, setPostModalOpen] = useState<boolean>(false);

  // Set previous path
  // Keep a record of history
  useEffect(() => {
    if (router.query.prev) {
      if (prevPath) {
        const historyArr = [...history];
        historyArr.push(router.query.prev as string);
        setHistory(historyArr);
      }
      setPrevPath(router.query.prev as string);
    } else if (router.query.back) {
      const historyArr = [...history];
      const prev = historyArr.shift();
      setPrevPath(prev || false);
      setHistory(historyArr);
    } else {
      setPrevPath(false);
      setHistory([router.asPath]);
    }
  }, [router.query]);

  // Set the back link where appropriate
  useEffect(() => {
    if (prevPath && typeof prevPath === 'string') {
      const route = parseUrl(prevPath);
      let href = router.pathname;
      if (route.params.username) {
        href = `${href}?username=${route.params.username}`;
      }
      if (route.params.postId) {
        href = `${href}&postId=${route.params.postId}`;
      }
      if (route.params.collectionId) {
        href = `${href}&collectionId=${route.params.collectionId}`;
      }
      if (route.params.filter) {
        href = `${href}&filter=[${route.params.filter}]`;
      }
      setBackLink({
        href,
        as: prevPath,
        options: {
          shallow: true,
          scroll: false,
        },
      });
    } else {
      setBackLink(null);
    }
  }, [prevPath]);

  const values = useMemo(
    () => ({
      backLink,
      isPostModalOpen,
      setPostModalOpen,
    }),
    [backLink, isPostModalOpen],
  );

  return (
    <NavContext.Provider value={values}>{props.children}</NavContext.Provider>
  );
};

export const useNav = (): NavContextInterface => {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error('useNav can only be used inside NavProvider');
  }
  return context;
};

export default NavProvider;
