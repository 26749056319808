import { useContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import SignupBannerContext from './context';
import { ISignupBannerState, ISignupBannerProviderProps } from './types';

const defaultMessage = 'Join the conversation';

const SignupBannerProvider: React.FC<ISignupBannerProviderProps> = props => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(defaultMessage);

  const open = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const close = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const resetMessage = useCallback(() => {
    setMessage(defaultMessage);
  }, []);

  const contextValue = useMemo(
    () => ({
      isExpanded,
      open,
      close,
      message,
      setMessage,
      resetMessage,
    }),
    [isExpanded, open, close, message, setMessage, resetMessage],
  );

  return (
    <SignupBannerContext.Provider value={contextValue}>
      {props.children}
    </SignupBannerContext.Provider>
  );
};

SignupBannerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSignupBanner = (): ISignupBannerState =>
  useContext(SignupBannerContext);

export default SignupBannerProvider;
